import { useEffect } from "react";
import {
  isAdmin,
  isAdminOrAdminCoop,
} from "views/rai/utenti/components/costants";

const AdminWrapper = ({ children }) => {
  useEffect(() => {
    if (isAdminOrAdminCoop()) {
      if (!sessionStorage.getItem("adminPageReloaded")) {
        console.log("Admin detected, reloading page...");
        sessionStorage.setItem("adminPageReloaded", "true");
        window.location.reload();
      } else {
        sessionStorage.removeItem("adminPageReloaded");
      }
    }
  }, []);
  console.log("isAdminOrAdminCoop:", isAdminOrAdminCoop());
  console.log("isAdmin:", isAdmin());

  // const adminJSX = (
  //   <div className="content">Sistema sospeso per problemi amministrativi</div>
  // );

  // if (isAdminOrAdminCoop()) {
  //   return adminJSX;
  // }

  return children;
};

export default AdminWrapper;
