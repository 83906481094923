import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardSubtitle,
} from "reactstrap";
import { connect } from "react-redux";
import _, { get } from "lodash";
import DateRangePickerWrapper from "components/DateRangePicker/DateRangePickerWrapper";
import moment from "moment";
import * as Commands from "./redux/analyticsSoci.commands";
import ReactTable from "react-table";
import ReactTableFilterSelectComponent from "../../../components/ReactTableFilter/reactTable.filter.select.component";
import StarRatings from "react-star-ratings";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";
import Fade from "react-reveal/Fade";
import { CSVDownload } from "react-csv";
import AdminWrapper from "components/AdminWrapper/AdminWrapper";

let csvData = [
  [
    "SOCIO",
    "DATA",
    "INIZIO",
    "FINE",
    "TIMBRATE",
    "LOTTO",
    "VALUTAZIONE",
    "TIPOLOGIA",
    "LUOGO",
    "PRODUZIONE",
    "ORE CONTRATTO",
    "ORE",
    "ORE MESE",
    "SETTORE",
    "ORE SETTORE",
    "ORDINE",
    "SERVIZIO",
    "CONTRATTO",
  ],
];

class AnalitycsStatisticheLavoratoriComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new moment(),
      endDate: new moment(),
      showExportDropDown: false,
      filters: {},
      data: [],
      loading: false,
      downloadCsv: false,
    };
  }

  onDatesChanged(startDate, endDate) {
    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        this.caricaDati(startDate, endDate);
      }
    );
  }

  caricaDati = async (startDate, endDate) => {
    const startDateClone = startDate.clone();
    const endDateClone = endDate.clone();
    this.setState({ loading: true });
    await Commands.OttieniAssegnazioniAnalytics(
      startDateClone.format("YYYY-MM-DD"),
      endDateClone.add(1, "days").format("YYYY-MM-DD")
    );
    this.setState({ loading: false });
  };

  toggleDownloadCsv = () => {
    const records = this.statsTable.getResolvedState().sortedData;

    csvData = [
      [
        "SOCIO",
        "ORE",
        "DATA",
        "INIZIO",
        "FINE",
        "TIMBRATE",
        "LOTTO",
        "VALUTAZIONE",
        "TIPOLOGIA",
        "LUOGO",
        "PRODUZIONE",
        "ORE CONTRATTO",
        "ORE MESE",
        "SETTORE",
        "ORE SETTORE",
        "ORDINE",
        "SERVIZIO",
        "CONTRATTO",
      ],
    ];

    records.forEach((v) => {
      csvData.push([
        v.worker,
        v.oreAssegnazione,
        moment.unix(v.assignementStartTime).format("DD/MM/YYYY"),
        moment.unix(v.assignementStartTime).format("HH:mm"),
        moment.unix(v.assignementEndTime).format("HH:mm"),
        "",
        v.lotto,
        v.valutazione,
        v.tipologia,
        v.luogo,
        v.produzione,
        v.oreContratto,
        v.oreMese,
        v.settore,
        v.oreSettore,
        v.ordine,
        v.servizio,
        v.contratto,
      ]);
    });

    const callBack = () => {
      this.setState({ downloadCsv: false });
    };

    this.setState({ downloadCsv: true }, callBack);
  };

  downloadReportNew = async (rptName) => {
    // the 'sortedData' property contains the currently accessible records based on the filter and sort
    const currentRecords = this.statsTable
      ? this.statsTable.getResolvedState().sortedData
      : this.props.data;
    const ids = currentRecords.map((item) => {
      return item._original.assignementId;
    });

    const workersIds = currentRecords.map((item) => {
      return item._original.workerId;
    });
    const workersIdsUniq = _.uniq(workersIds);

    const res = await Commands.GetReport(ids, rptName, workersIdsUniq);

    if (res.error) {
      ShowConfirmationDialog({
        title: "Errore durante la creazione del report",
        text: res.message,
        canEscapeKeyCancel: true,
      });
      return;
    }

    let extension = ".pdf";
    let contentType = "application/pdf";

    switch (rptName) {
      case "rptXlsOreLavoratoriGescoop":
        extension = ".xlsx";
        contentType = "application/vnd.ms-excel";
    }

    const element = document.createElement("a");
    element.target = "_blank";
    element.href = res.data;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  toggleShowExportDropDown = () =>
    this.setState({ showExportDropDown: !this.state.showExportDropDown });

  render() {
    const content = (
      <>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  {/*Filtri*/}
                  <Row>
                    <Col xs={12} lg={8}>
                      <DateRangePickerWrapper
                        disabled={false}
                        initialStartDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        initialEndDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        small
                        enableOutsideDays={true}
                        startDatePlaceholderText={"Data inizio"}
                        endDatePlaceholderText={"Data fine"}
                        showDefaultInputIcon
                        isOutsideRange={() => false}
                        onChange={(startDate, endDate) =>
                          this.onDatesChanged(startDate, endDate)
                        }
                      />
                    </Col>

                    <Col xs={12} lg={4} className="d-flex justify-content-end">
                      <ButtonDropdown
                        isOpen={this.state.showExportDropDown}
                        toggle={this.toggleShowExportDropDown}
                      >
                        <DropdownToggle className="btn btn-primary" caret>
                          Reports
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              this.downloadReportNew("rptPdfSociRiepilogoSoci");
                            }}
                          >
                            Riepilogo soci
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.downloadReportNew("rptPdfSociDettaglioSoci");
                            }}
                          >
                            Dettaglio soci
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.downloadReportNew(
                                "rptXlsOreLavoratoriGescoop"
                              );
                            }}
                          >
                            Tracciato gescoop
                          </DropdownItem>
                          <DropdownItem onClick={this.toggleDownloadCsv}>
                            {this.state.downloadCsv ? (
                              <CSVDownload data={csvData} target="_blank" />
                            ) : (
                              "Tracciato CSV"
                            )}
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <Fade when={this.state.loading}>
                    <CardSubtitle>
                      Caricamento in corso{" "}
                      <i className="fa fa-spin fa-spinner" />
                    </CardSubtitle>
                  </Fade>
                  <ReactTable
                    data={this.props.data}
                    ref={(r) => (this.statsTable = r)}
                    filterable
                    resizable={true}
                    columns={[
                      {
                        Header: "Socio",
                        id: "worker",
                        accessor: (row) => {
                          return row.worker;
                        },
                        width: 250,
                        Footer: (row) => {
                          const count = _(row.data).groupBy("worker").size();
                          return (
                            <div className="assegnazioneCompleta">
                              Cnt: {count}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row.worker
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "worker";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Società",
                        accessor: "societa",
                        width: 100,
                        Footer: (row) => {
                          const count = _(row.data).groupBy("societa").size();
                          return (
                            <div className="assegnazioneCompleta">
                              Cnt: {count}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.societa
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "societa";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Data",
                        accessor: "assignementStartTime",
                        width: 80,
                        id: "data",
                        Cell: (row) =>
                          `${moment
                            .unix(row.original.assignementStartTime)
                            .format("DD/MM/YY")}`,
                        Filter: <></>,
                      },
                      {
                        Header: "Inizio",
                        accessor: "assignementStartTime",
                        width: 70,
                        Cell: (row) =>
                          `${moment
                            .unix(row.original.assignementStartTime)
                            .format("HH:mm")}`,
                        Footer: (row) => {
                          const count = _(row.data)
                            .groupBy("assignementStartTime")
                            .size();
                          return (
                            <div className="assegnazioneCompleta">
                              Cnt: {count}
                            </div>
                          );
                        },
                        Filter: <></>,
                      },
                      {
                        Header: "Fine",
                        accessor: "assignementEndTime",
                        width: 70,
                        Cell: (row) =>
                          `${moment
                            .unix(row.original.assignementEndTime)
                            .format("HH:mm")}`,
                        Footer: (row) => {
                          const count = _(row.data)
                            .groupBy("assignementEndTime")
                            .size();
                          return (
                            <div className="assegnazioneCompleta">
                              Cnt: {count}
                            </div>
                          );
                        },
                        Filter: <></>,
                      },
                      {
                        Header: "Timbrate",
                        width: 120,
                        Cell: (row) => {
                          let orarioInizio = "-";
                          let orarioFine = "-";

                          if (row.original.stampStartTimeDeclared) {
                            orarioInizio = moment
                              .unix(row.original.stampStartTimeDeclared)
                              .format("HH:mm");
                          }

                          if (row.original.stampEndTimeDeclared) {
                            orarioFine = moment
                              .unix(row.original.stampEndTimeDeclared)
                              .format("HH:mm");
                          }

                          return orarioInizio + " -> " + orarioFine;
                        },
                        Filter: <></>,
                      },
                      {
                        Header: "Lotto",
                        accessor: "lotto",
                        width: 80,
                        Footer: (row) => {
                          const count = _(row.data).groupBy("lotto").size();
                          return (
                            <div className="assegnazioneCompleta">
                              Cnt: {count}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.lotto
                          );
                        },

                        Filter: ({ filter, onChange }) => {
                          const filterName = "lotto";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Valutazione",
                        accessor: "valutazione",
                        width: 120,
                        Cell: (row) => (
                          <StarRatings
                            rating={row.value}
                            starRatedColor="yellow"
                            numberOfStars={5}
                            starDimension="15px"
                            starSpacing="3px"
                            name="rating"
                          />
                        ),
                        Filter: <></>,
                      },
                      {
                        Header: "Tipologia",
                        accessor: "tipologia",
                        width: 100,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.tipologia
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "tipologia";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Luogo",
                        accessor: "luogo",
                        width: 200,
                        Footer: (row) => {
                          const count = _(row.data).groupBy("luogo").size();
                          return (
                            <div className="assegnazioneCompleta">
                              Cnt: {count}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.luogo
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "luogo";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Produzione",
                        width: 300,
                        accessor: "produzione",
                        Footer: (row) => {
                          const count = _(row.data)
                            .groupBy("produzione")
                            .size();
                          return (
                            <div className="assegnazioneCompleta">
                              Cnt: {count}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.produzione
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "produzione";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Ore contratto",
                        accessor: "oreContratto",
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.oreContratto
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "oreContratto";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Ore",
                        id: "oreAssegnazione",
                        accessor: "oreAssegnazione",
                        width: 90,
                        Filter: <></>,
                        Footer: (row) => {
                          let oreTotali = 0;
                          _.map(row.data, (d) => {
                            oreTotali = oreTotali + d.oreAssegnazione;
                          });

                          return (
                            <div className="assegnazioneCompleta">
                              Tot: {oreTotali.toLocaleString()}
                            </div>
                          );
                        },
                      },
                      {
                        Header: "Ore Mese",
                        id: "oreMese",
                        accessor: "oreMese",
                        width: 100,
                        Filter: <></>,
                      },
                      {
                        Header: "Settore",
                        accessor: "settore",
                        width: 150,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.settore
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "settore";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Ore Settore",
                        id: "oreSettore",
                        accessor: "oreSettore",
                        width: 100,
                        Filter: <></>,
                      },

                      {
                        Header: "Ordine",
                        accessor: "ordine",
                        width: 80,
                        Footer: (row) => {
                          const count = _(row.data).groupBy("ordine").size();
                          return (
                            <div className="assegnazioneCompleta">
                              Cnt: {count}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.ordine
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "ordine";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Servizio",
                        accessor: "servizio",
                        width: 100,
                        Footer: (row) => {
                          const count = _(row.data).groupBy("servizio").size();
                          return (
                            <div className="assegnazioneCompleta">
                              Cnt: {count}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.servizio
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "servizio";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      // {
                      //     Header: "Assegnazione",
                      //     accessor: "statoAssegnazione",
                      //     width: 160,
                      //     Cell: (cellInfo => {
                      //         const state = cellInfo.original.statoAssegnazione.toLowerCase();
                      //         switch (state) {
                      //             case "assigned":
                      //                 return <div className={"ordineApprovato"}>ASSEGNATO</div>
                      //             default:
                      //                 return <div className={"ordineRifiutato"}>NON ASSEGNATO</div>
                      //         }
                      //     }),
                      //
                      //     Filter:<></>
                      //
                      // },
                      // {
                      //     Header: "Ordine",
                      //     accessor: "statoOrdine",
                      //     width: 160,
                      //     Cell: (cellInfo => {
                      //         const state = cellInfo.original.statoOrdine.toLowerCase();
                      //         switch (state) {
                      //             case "firmato":
                      //                 return <div className={"ordineApprovato"}>{state}</div>
                      //             default:
                      //                 return <div className={"ordineRifiutato"}>{state}</div>
                      //         }
                      //     }),
                      //
                      //     filterMethod: (filter, row) => {
                      //         if (filter && filter.value && filter.value.length === 0) return true;
                      //         return filter.value.some(f => f.value === row._original.statoOrdine);
                      //     },
                      //     Filter: ({filter, onChange}) => {
                      //
                      //         const filterName = 'statoOrdine';
                      //         return <ReactTableFilterSelectComponent
                      //             options={  _.sortBy(this.props.data, [{filterName}]) }
                      //             optionValue={filterName }
                      //             optionLabel={filterName}
                      //             filterName
                      //             value={this.state.filters[filterName]}
                      //             onChange={(value) => this.setState({
                      //                 filters: {
                      //                     ...this.state.filters,
                      //                     [filterName]: value
                      //                 }
                      //             }, () => onChange(value))}
                      //         />
                      //     },
                      //
                      // },
                      {
                        Header: "Contratto",
                        accessor: "contratto",
                        width: 160,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.contratto
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "contratto";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                { filterName },
                              ])}
                              optionValue={filterName}
                              optionLabel={filterName}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                    ]}
                    pageSizeOptions={[5, 10, 20, 50, 100, 150, 200, 250]}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    nextText={"Avanti"}
                    previousText={"Indietro"}
                    pageText={"Pagina"}
                    ofText={"di"}
                    rowsText={"elementi"}
                    noDataText={"Nessun dato"}
                    loadingText={"Caricamento in corso"}
                    loading={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );

    return <AdminWrapper children={content} />;
  }
}

const mapStateToProps = (state) => {
  const data = {
    data: get(state, "raiAnalyticsSoci.data", []),
    loading: get(state, "raiAnalyticsSoci.loading", false),
  };

  return data;
};

export default connect(mapStateToProps, () => ({}))(
  AnalitycsStatisticheLavoratoriComponent
);
