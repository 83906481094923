import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardSubtitle,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  FormText,
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import { connect } from "react-redux";
import { get } from "lodash";

// For auto registry purpose
import "./redux/document.reducer";
import * as Commands from "./redux/document.commands";
import CreateComponent from "./components/document.create.component";
import UpdateDocument from "./components/document.update.component";
import { b64toBlob } from "utilities/report";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from "lodash";
import moment from "moment";
import ReactTableFilterSelectComponent from "components/ReactTableFilter/reactTable.filter.select.component";
import Input from "reactstrap/es/Input";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";
import Picker from "react-month-picker";
import Select from "react-select";
import SweetAlert from "sweetalert-react";
import AdminWrapper from "components/AdminWrapper/AdminWrapper";

class DocumentComponent extends Component {
  constructor(props) {
    super(props);

    this.pickAMonth = React.createRef();

    var anni = [];
    for (
      var i = new Date().getFullYear() - 7;
      i < new Date().getFullYear() + 3;
      i++
    ) {
      anni.push({
        id: i.toString(),
        description: i.toString(),
      });
    }

    let meseIniziale = new Date().getMonth();
    let annoIniziale = new Date().getFullYear();

    if (meseIniziale <= 0) {
      meseIniziale = 12;
      annoIniziale -= 1;
    }

    const mesiStr = {
      1: "Gennaio",
      2: "Febbraio",
      3: "Marzo",
      4: "Aprile",
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre",
      11: "Novembre",
      12: "Dicembre",
    };

    this.state = {
      data: [],
      showCreateModal: false,
      showValidateModal: false,
      loading: false,
      selectedRecordForUpdate: {},
      showDeleteAlert: false,
      selectedRecordForDelete: {},
      showActionDropDown: false,
      filters: {
        anno: {
          value: [
            {
              id: annoIniziale.toString(),
              description: annoIniziale.toString(),
            },
          ],
          operator: "year",
        },
        mese: {
          value: [
            {
              id: meseIniziale.toString(),
              description: mesiStr[meseIniziale],
            },
          ],
          operator: "month",
        },
      },
      fullText: "",
      showDocumentViewer: false,
      loadingFilters: false,
      baseFilters: {},
      page: 0,
      pages: 0,
      pageSize: 10,
      anni: anni,
      sorted: [],
    };
  }

  caricaDati = async () => {
    this.setState({ loading: true });

    let filters = [];

    Object.keys(this.state.filters).map((key) => {
      if (!this.state.filters[key].value) {
        // console.log("SAFEEEEEE");
        return;
      }
      let values = this.state.filters[key].value.map((obj) => obj.id);

      if (values.length === 0) return;

      values = JSON.stringify(values);
      filters.push({
        name: key,
        value: values,
        valueType: "string",
        operator: this.state.filters[key].operator,
      });
    });

    const res = await Commands.ReadAll(filters);
    this.setState({ loading: false });

    if (res.hasError) {
      return;
    }

    this.setState({ data: res.data });
  };

  caricaFiltri = async () => {
    this.setState({ loadingFilters: true });

    const res = await Commands.GetAllFilters();

    if (!res.hasError) {
      this.setState({ baseFilters: res.data });
    }

    this.setState({ loadingFilters: false });
  };

  caricaWorker = async () => {
    this.setState({ loading: true });
    await Commands.ReadAllWorkers();
    this.setState({ loading: false });
  };

  toggleShowActionDropDown = () => {
    this.setState({ showActionDropDown: !this.state.showActionDropDown });
  };

  componentDidMount() {
    this.caricaDati();
    this.caricaWorker();
    this.caricaFiltri();
  }

  toggleShowCreateModal = () => {
    this.setState({
      showCreateModal: !this.state.showCreateModal,
    });
  };

  toggleShowDocumentViewer = (record) => {
    this.setState({
      showDocumentViewer: !this.state.showDocumentViewer,
      selectedRecordForUpdate: [record],
    });
  };

  toggleShowValidateModal = () => {
    const currentRecords = this.selectTable.getResolvedState().sortedData;
    this.setState({
      showDocumentViewer: !this.state.showDocumentViewer,
      selectedRecordForUpdate: currentRecords,
    });
  };

  toggleShowSendDocumentsWithMail = async () => {
    const doAction = await ShowConfirmationDialog({
      title: "Invia documenti",
      text: "Inserisci l'indirizzo e-mail al quale inviare la mail con i documenti",
      confirmBtnText: "Invia",
      cancelBtnText: "Annulla",
      canEscapeKeyCancel: true,
      input: true,
      placeholder: "E-Mail a cui inviare",
      validationMsg: "Inserire email a cui inviare i documenti",
    });

    const mail = doAction.response;

    if (!doAction.confirm) return;
    // console.log("Invio mail a ", mail)
    this.sendDocumentsWithMail(mail);
  };

  elimina = async () => {
    var res = await Commands.DeleteDocument(
      this.state.selectedRecordForDelete.id
    );

    if (res.hasError) {
      alert("Impossibile eliminare il settore");
      return;
    }
    this.toggleDeleteAlert();
  };

  toggleDeleteAlert = (record) =>
    this.setState({
      showDeleteAlert: !this.state.showDeleteAlert,
      selectedRecordForDelete: record,
    });

  actions = (cellInfo) => (
    <div className="actions-right">
      {get(cellInfo, "original._deleting", false) ? (
        <i className="fa fa-spin fa-spinner" />
      ) : null}
      <Button
        color="danger"
        size="sm"
        onClick={() => this.toggleDeleteAlert(cellInfo.original)}
        className={classNames("btn-icon btn-link like")}
      >
        <i className="tim-icons icon-simple-remove" />
      </Button>{" "}
      <Button
        color="warning"
        size="sm"
        onClick={() => this.toggleShowDocumentViewer(cellInfo.original)}
        className={classNames("btn-icon btn-link like")}
      >
        <i className="tim-icons icon-pencil" />
      </Button>{" "}
    </div>
  );

  downloadAllDocumentsSeparatedZIP = async () => {
    const currentRecords = this.selectTable
      ? this.selectTable.getResolvedState().sortedData
      : this.props.data;
    const ids = currentRecords.map((item) => {
      return item._original.id;
    });

    const res = await Commands.DownloadAllDocumentsSeparatedZIP(ids);

    if (res.error) {
      ShowConfirmationDialog({
        title: "Errore durante la creazione del report",
        text: res.message,
        canEscapeKeyCancel: true,
      });
      return;
    }

    let extension = ".zip";

    const element = document.createElement("a");
    element.href = "data:text/plain;base64," + res.data.Base64;
    element.download = "unificato.zip";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  downloadAllDocumentsUniquePDF = async () => {
    const currentRecords = this.selectTable
      ? this.selectTable.getResolvedState().sortedData
      : this.props.data;
    const ids = currentRecords.map((item) => {
      return item._original.id;
    });

    const res = await Commands.DownloadAllDocumentsUniquePDF(ids);

    if (res.error) {
      ShowConfirmationDialog({
        title: "Errore durante la creazione del report",
        text: res.message,
        canEscapeKeyCancel: true,
      });
      return;
    }

    const element = document.createElement("a");
    element.href = "data:application/pdf;base64," + res.data.Base64;
    element.download = "unificato.pdf";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  sendDocumentsToWorkerWithMail = async () => {
    const currentRecords = this.selectTable
      ? this.selectTable.getResolvedState().sortedData
      : this.props.data;
    const ids = currentRecords.map((item) => {
      return item._original.id;
    });

    const res = await Commands.SendDocumentsToWorkersWithMail(ids);

    if (res.hasError) {
      ShowConfirmationDialog({
        title: "Errore durante l'invio della mail",
        text: res.message,
        canEscapeKeyCancel: true,
      });
      return;
    }

    ShowConfirmationDialog({
      title: "Successo",
      text: "Mail inviate",
      confirmBtnText: "Va bene",
      showCancel: false,
      canEscapeKeyCancel: false,
    });
  };

  sendDocumentsWithPec = async () => {
    const currentRecords = this.selectTable
      ? this.selectTable.getResolvedState().sortedData
      : this.props.data;
    const ids = currentRecords.map((item) => {
      return item._original.id;
    });

    const res = await Commands.SendDocumentsWithMailPec(ids);

    if (res.hasError) {
      ShowConfirmationDialog({
        title: "Errore durante l'invio della mail",
        text: res.message,
        canEscapeKeyCancel: true,
      });
      return;
    }

    ShowConfirmationDialog({
      title: "Successo",
      text: "Mail inviate",
      confirmBtnText: "Va bene",
      showCancel: false,
      canEscapeKeyCancel: false,
    });
  };

  sendDocumentsWithMail = async (mail) => {
    const currentRecords = this.selectTable
      ? this.selectTable.getResolvedState().sortedData
      : this.props.data;
    const ids = currentRecords.map((item) => {
      return item._original.id;
    });

    const res = await Commands.SendDocumentsWithMail(ids, [mail], [], []);

    if (res.error) {
      ShowConfirmationDialog({
        title: "Errore durante l'invio della mail",
        text: res.message,
        canEscapeKeyCancel: true,
      });
      return;
    }
  };

  render() {
    const content = (
      <>
        <SweetAlert
          show={this.state.showSendDocumentsWithMail}
          showCancelButton
          title="Invio tramite e-mail?"
          text="Inserire indirizzo e-mail a cui inviare i documenti"
          onConfirm={(c) => {
            // console.log("sendDocumentsWithMail", c)
            // this.sendDocumentsWithMail();
          }}
          onCancel={() => {
            this.setState({ showAlert: false });
          }}
        />

        {this.state.showDeleteAlert ? (
          <ReactBSAlert
            warning
            style={{ display: "block" }}
            title="Confermare l'eliminazione?"
            onConfirm={() => this.elimina()}
            onCancel={() => this.toggleDeleteAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Si"
            cancelBtnText="No"
            showCancel
            btnSize=""
          >
            Stai per eliminare in modo definitivo:{" "}
            {_.get(this.state, "selectedRecordForDelete.description", "")}
          </ReactBSAlert>
        ) : null}

        {/*Modal creazione e aggiornamento uorg*/}
        {this.state.showCreateModal && (
          <CreateComponent
            show={this.state.showCreateModal}
            toggle={this.toggleShowCreateModal}
          />
        )}

        {this.state.showDocumentViewer && (
          <UpdateDocument
            documents={this.state.selectedRecordForUpdate}
            show={this.state.showDocumentViewer}
            toggle={this.toggleShowDocumentViewer}
          />
        )}

        {/*Contenuto pagina*/}
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  {/*Filtri*/}
                  <Row>
                    <Col className="mr-auto">
                      <button
                        className="btn btn-primary "
                        id="nuovoModal"
                        onClick={() => this.toggleShowCreateModal()}
                      >
                        <i className="tim-icons icon-simple-add" />
                        Carica documenti
                      </button>
                      <button
                        className="btn btn-primary "
                        id="nuovoModal"
                        onClick={() => this.toggleShowValidateModal()}
                      >
                        <i className="tim-icons icon-simple-add" />
                        Valida documenti importarti
                      </button>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <ButtonDropdown
                        isOpen={this.state.showActionDropDown}
                        toggle={this.toggleShowActionDropDown}
                      >
                        <DropdownToggle className="btn btn-primary" caret>
                          Azioni
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={this.sendDocumentsToWorkerWithMail}
                          >
                            Invia documenti tramite mail al lavoratore
                          </DropdownItem>
                          <DropdownItem onClick={this.sendDocumentsWithPec}>
                            Invia documenti tramite PEC al lavoratore
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.toggleShowSendDocumentsWithMail}
                          >
                            Invia documenti tramite E-Mail
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.downloadAllDocumentsUniquePDF}
                          >
                            Scarica unico PDF
                          </DropdownItem>
                          <DropdownItem
                            onClick={this.downloadAllDocumentsSeparatedZIP}
                          >
                            Scarica ZIP
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                      <button
                        id="refresh"
                        onClick={() => {
                          this.caricaDati(this.state.page, this.state.pageSize);
                        }}
                        className="btn btn-primary btn-fab btn-icon "
                      >
                        <i className="tim-icons icon-refresh-01"></i>
                      </button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="refresh"
                        delay={0}
                      >
                        Ricarica i dati
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <CardTitle tag="h4">Elenco documenti</CardTitle>
                    </Col>
                    <Col>
                      <Input
                        onChange={(e) => {
                          this.setState({ fullText: e.target.value }, () => {
                            this.caricaDati();
                          });
                        }}
                        value={this.state.fullText}
                        placeHolder="Ricerca...."
                      />
                    </Col>
                  </Row>
                  <Fade when={this.state.loading}>
                    <CardSubtitle>
                      Caricamento in corso{" "}
                      <i className="fa fa-spin fa-spinner" />
                    </CardSubtitle>
                  </Fade>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    ref={(r) => {
                      this.selectTable = r;
                    }}
                    getTheadFilterThProps={(state, rowInfo, column) => {
                      if (column.Header === "Periodo") {
                        return {
                          style: { overflow: "visible" },
                        };
                      }
                      return {
                        style: { overflow: "hidden" },
                      };
                    }}
                    data={this.state.data}
                    filterable={true}
                    resizable={true}
                    defaultPageSize={10}
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                        show: false,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.worker.id
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "id";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, ["id"])}
                              optionValue={"id"}
                              optionLabel={"id"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "",
                        id: "azioni",
                        Cell: this.actions,
                        width: 80,
                        sortable: false,
                        filterable: false,
                      },

                      {
                        id: "lavoratore",
                        Header: "lavoratore",
                        accessor: "worker.cognomeNome",
                        minWidth: 200,
                        resizable: true,
                        Filter: ({ onChange }) => (
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            //FIXME: Porcata per settare zIndex
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            //FINE PORCATA
                            name="multipleSelect"
                            placeholder={"..."}
                            closeMenuOnSelect={true}
                            isLoading={this.state.loadingFilters}
                            isMulti
                            value={_.get(
                              this.state,
                              "filters.worker_id.value",
                              []
                            )}
                            onChange={(value) => {
                              var filters = this.state.filters;
                              filters.worker_id = {
                                operator: "oneof",
                                value: value,
                              };
                              this.setState({ filters }, () =>
                                this.caricaDati()
                              );
                            }}
                            getOptionLabel={(w) => w.description}
                            getOptionValue={(w) => w.id}
                            options={[
                              {
                                value: "",
                                description: "Lavoratori",
                                isDisabled: true,
                              },
                              ...(_.get(
                                this.state,
                                "baseFilters.worker_id",
                                []
                              ) || []),
                            ]}
                          />
                        ),
                      },
                      {
                        id: "matricola",
                        Header: "matricola",
                        accessor: "worker.matricola",
                        minWidth: 200,
                        resizable: true,
                      },
                      {
                        Header: "Coop",
                        accessor: "worker.cooperativaAppartenenza",
                        width: 120,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) =>
                              f.value ===
                              row._original.worker.cooperativaAppartenenza
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "worker.cooperativaAppartenenza";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.state.data, [
                                "worker.cooperativaAppartenenza",
                              ])}
                              optionValue={"worker.cooperativaAppartenenza"}
                              optionLabel={"worker.cooperativaAppartenenza"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Cantiere abituale",
                        accessor: "worker.luogoPartenza",
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) =>
                              f.value === row._original.worker.luogoPartenza
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "worker.luogoPartenza";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.state.data, [
                                "worker.luogoPartenza",
                              ])}
                              optionValue={"worker.luogoPartenza"}
                              optionLabel={"worker.luogoPartenza"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Mese",
                        id: "mese",
                        accessor: "mese",
                        Cell: (row) => {
                          return moment
                            .unix(row.original.periodUnix)
                            .format("MMMM");
                        },
                        Filter: () => (
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder=""
                            //FIXME: Porcata per settare zIndex
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            //FINE PORCATA

                            name="multipleSelect"
                            closeMenuOnSelect={true}
                            isMulti
                            isLoading={this.state.loadingFilters}
                            getOptionLabel={(w) => w.description}
                            getOptionValue={(w) => w.id}
                            value={_.get(this.state, "filters.mese.value", [])}
                            onChange={(value) => {
                              var filters = this.state.filters;
                              filters.mese = {
                                operator: "month",
                                value: value,
                              };
                              this.setState({ filters }, () =>
                                this.caricaDati()
                              );
                            }}
                            options={[
                              {
                                value: "",
                                description: "Seleziona mese",
                                isDisabled: true,
                              },
                              {
                                id: "1",
                                description: "Gennaio",
                              },
                              {
                                id: "2",
                                description: "Febbraio",
                              },
                              {
                                id: "3",
                                description: "Marzo",
                              },
                              {
                                id: "4",
                                description: "Aprile",
                              },
                              {
                                id: "5",
                                description: "Maggio",
                              },
                              {
                                id: "6",
                                description: "Giugno",
                              },
                              {
                                id: "7",
                                description: "Luglio",
                              },
                              {
                                id: "8",
                                description: "Agosto",
                              },
                              {
                                id: "9",
                                description: "Settembre",
                              },
                              {
                                id: "10",
                                description: "Ottobre",
                              },
                              {
                                id: "11",
                                description: "Novembre",
                              },
                              {
                                id: "12",
                                description: "Dicembre",
                              },
                            ]}
                          />
                        ),
                      },
                      {
                        Header: "Anno",
                        id: "anno",
                        accessor: "anno",
                        Cell: (row) => {
                          return moment
                            .unix(row.original.periodUnix)
                            .format("YYYY");
                        },
                        Filter: () => (
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder=""
                            //FIXME: Porcata per settare zIndex
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            //FINE PORCATA
                            name="multipleSelect"
                            closeMenuOnSelect={true}
                            isMulti
                            isLoading={this.state.loadingFilters}
                            getOptionLabel={(w) => w.description}
                            getOptionValue={(w) => w.id}
                            value={_.get(this.state, "filters.anno.value", [])}
                            onChange={(value) => {
                              var filters = this.state.filters;
                              filters.anno = { operator: "year", value: value };
                              this.setState({ filters }, () =>
                                this.caricaDati()
                              );
                            }}
                            options={[
                              {
                                value: "",
                                description: "Seleziona anno",
                                isDisabled: true,
                              },
                              ...this.state.anni,
                            ]}
                          />
                        ),
                      },
                      {
                        Header: "Tipo",
                        id: "tipo",
                        accessor: "documentType.description",
                        Filter: () => (
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder=""
                            //FIXME: Porcata per settare zIndex
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            //FINE PORCATA
                            name="multipleSelect"
                            closeMenuOnSelect={true}
                            isMulti
                            isLoading={this.state.loadingFilters}
                            getOptionLabel={(w) => w.description}
                            getOptionValue={(w) => w.id}
                            value={_.get(
                              this.state,
                              "filters.document_type_id.value",
                              []
                            )}
                            onChange={(value) => {
                              var filters = this.state.filters;
                              filters.document_type_id = {
                                operator: "oneof",
                                value: value,
                              };
                              this.setState({ filters }, () =>
                                this.caricaDati()
                              );
                            }}
                            options={[
                              {
                                value: "",
                                description: "Seleziona tipologia documenti",
                                isDisabled: true,
                              },
                              ...(_.get(
                                this.state,
                                "baseFilters.document_type_id",
                                []
                              ) || []),
                            ]}
                          />
                        ),
                      },
                      {
                        Header: "Stato",
                        accessor: "state",
                        width: 120,
                        Filter: ({ onChange }) => (
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            //FIXME: Porcata per settare zIndex
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            //FINE PORCATA                                                    placeholder=""
                            name="multipleSelect"
                            placeholder={"..."}
                            closeMenuOnSelect={true}
                            isLoading={this.state.loadingFilters}
                            isMulti
                            value={_.get(this.state, "filters.state.value", [])}
                            onChange={(value) => {
                              var filters = this.state.filters;
                              filters.state = {
                                operator: "oneof",
                                value: value,
                              };
                              this.setState({ filters }, () =>
                                this.caricaDati()
                              );
                            }}
                            getOptionLabel={(w) => w.description}
                            getOptionValue={(w) => w.id}
                            options={[
                              {
                                value: "",
                                description: "Stato",
                                isDisabled: true,
                              },
                              ...[
                                {
                                  id: "CLASSIFICATO",
                                  description: "CLASSIFICATO",
                                },
                                {
                                  id: "DA CLASSIFICARE",
                                  description: "DA CLASSIFICARE",
                                },
                              ],
                            ]}
                          />
                        ),
                      },
                      {
                        Header: "Privato",
                        accessor: "privato",
                        width: 120,
                        show: false,
                      },

                      ...this.getMetasColumns(),
                    ]}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    nextText={"Avanti"}
                    previousText={"Indietro"}
                    pageText={"Pagina"}
                    ofText={"di"}
                    rowsText={"elementi"}
                    noDataText={"Nessun dato"}
                    loadingText={"Caricamento in corso"}
                    loading={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );

    return <AdminWrapper children={content} />;
  }

  getMetasColumns = () => {
    // Get new columns from metas
    let newCols = {};

    this.state.data.map((row) => {
      Object.keys(row.metas).map((key) => (newCols[key] = row.metas[key]));
    });

    return Object.keys(newCols).map((meta) => {
      let col = {
        Id: meta,
        Header: meta,
        accessor: "metas." + meta + ".value",
        width: 120,
        filterable: false,
      };

      const type = newCols[meta].valueType;
      switch (type) {
        case "unix":
          col.Cell = (row) => {
            if (!row.value) return "-";
            return moment.unix(row.value).format("DD MMMM YYYY");
          };

          break;
        case "currency":
          col.Cell = (row) => {
            if (!row.value) return "-";
            return (
              <div className={"importoFatturato"}>
                {row.value.toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                }) + " €"}
              </div>
            );
          };

          col.Footer = (footer) => {
            const value = _.sum(
              _.map(footer.data, (d) => _.get(d, "metas." + meta + ".value", 0))
            );
            return (
              <div className={"importoFatturato"}>
                Tot:{" "}
                {value.toLocaleString("it-IT", { minimumFractionDigits: 2 })} €
              </div>
            );
          };

          break;
        case "float":
          col.Cell = (row) => {
            if (!row.value) return "-";
            return (
              <div className={"floatTotale"}>
                {row.value.toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                })}
              </div>
            );
          };

          col.Footer = (footer) => {
            const value = _.sum(
              _.map(footer.data, (d) => _.get(d, "metas." + meta + ".value", 0))
            );
            return (
              <div className={"floatTotale"}>
                {value.toLocaleString("it-IT", { minimumFractionDigits: 2 })}
              </div>
            );
          };

          break;
        default:
          break;
      }

      return col;
    });
  };

  _makeText = (m) => {
    if (m && m.year && m.month)
      return this._pickerLang.months[m.month - 1] + ". " + m.year;
    return "...";
  };

  _pickerLang = {
    months: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ],
    from: "Da",
    to: "A",
  };
}

const mapStateToProps = (state) => {
  const data = {
    workers: get(state, "documents.workers", []),
  };

  return data;
};

export default connect(mapStateToProps, () => ({}))(DocumentComponent);
