import React, { Component } from "react";
import {
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Fade from "react-reveal/Fade";
import ReactTable from "react-table";
import _, { get } from "lodash";
import { connect } from "react-redux";
import * as Commands from "../../redux/analyticsSoci.commands";
import moment from "moment";
import StarRatings from "react-star-ratings";
import ReactTableFilterSelectComponent from "../../../../../components/ReactTableFilter/reactTable.filter.select.component";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

class StatisticheSocioComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaded: false,
      filters: {},
      showExportDropDown: false,
    };
  }

  downloadReport = async (rptName) => {
    // the 'sortedData' property contains the currently accessible records based on the filter and sort
    const currentRecords = this.statsTable
      ? this.statsTable.getResolvedState().sortedData
      : this.props.data;
    const ids = currentRecords.map((item) => {
      return item._original.id;
    });

    const res = await Commands.RptSoci(ids, rptName);

    if (res.error) {
      ShowConfirmationDialog({
        title: "Errore durante la creazione del report",
        text: res.message,
        canEscapeKeyCancel: true,
      });
      return;
    }

    const element = document.createElement("a");
    element.target = "_blank";
    element.href = res.data;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  caricaDati = async (startDate, endDate) => {
    const startDateClone = startDate.clone();
    const endDateClone = endDate.clone();
    this.setState({ loading: true });
    await Commands.OttieniAssegnazioni(
      startDateClone.format("YYYY-MM-DD"),
      endDateClone.add(1, "days").format("YYYY-MM-DD"),
      ""
    );
    this.setState({ loading: false, loaded: true });
  };

  componentWillMount() {
    this.caricaDati(this.props.startDate, this.props.endDate);
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.startDate !== newProps.startDate ||
      this.props.endDate !== newProps.endDate
    ) {
      this.caricaDati(newProps.startDate, newProps.endDate);
    }
  }

  toggleShowExportDropDown = () =>
    this.setState({ showExportDropDown: !this.state.showExportDropDown });

  render() {
    if (!this.state.loaded) return <div>Caricamento dati in corso</div>;

    return (
      <Col xs={12} md={12}>
        <Card>
          <CardHeader>
            <Col xs={10} lg={4} className="d-flex justify-content-end">
              <Fade when={this.state.loading}>
                <CardSubtitle>
                  Caricamento in corso <i className="fa fa-spin fa-spinner" />
                </CardSubtitle>
              </Fade>

              <ButtonDropdown
                isOpen={this.state.showExportDropDown}
                toggle={this.toggleShowExportDropDown}
              >
                <DropdownToggle className="btn btn-primary" caret>
                  Reports
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      this.downloadReport("riepilogoSoci");
                    }}
                  >
                    Riepilogo soci
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      this.downloadReport("riepilogoSoci");
                    }}
                  >
                    Riepilogo data
                  </DropdownItem>
                  <DropdownItem disabled={true} onClick={""}>
                    Dettaglio data
                  </DropdownItem>
                  <DropdownItem disabled={true} onClick={""}>
                    Dettaglio socio
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={this.props.data}
              ref={(r) => (this.statsTable = r)}
              filterable
              resizable={true}
              columns={[
                {
                  Header: "Socio",
                  id: "socio",
                  accessor: (row) => {
                    return `${
                      _.get(row, "jsonWorker.cognome", "-") +
                      " " +
                      _.get(row, "jsonWorker.nome", "-")
                    }`;
                  },
                  width: 250,
                  Footer: (row) => {
                    const count = _(row.data)
                      .groupBy("jsonWorker.cognome")
                      .size();
                    return (
                      <div className="assegnazioneCompleta">Cnt: {count}</div>
                    );
                  },
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some(
                      (f) => f.value === row._original.jsonWorker.cognome
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName = "jsonWorker.cognome";
                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [{ filterName }])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                [filterName]: value,
                              },
                            },
                            () => onChange(value)
                          )
                        }
                      />
                    );
                  },
                },
                {
                  Header: "Data",
                  accessor: "startTime",
                  width: 80,
                  id: "data",
                  Cell: (row) =>
                    `${moment.unix(row.original.startTime).format("DD/MM/YY")}`,
                  Filter: <></>,
                },
                {
                  Header: "Inizio",
                  accessor: "startTime",
                  width: 60,
                  Cell: (row) =>
                    `${moment.unix(row.original.startTime).format("HH:mm")}`,
                  Footer: (row) => {
                    const count = _(row.data).groupBy("startTime").size();
                    return (
                      <div className="assegnazioneCompleta">Cnt: {count}</div>
                    );
                  },
                  Filter: <></>,
                },

                {
                  Header: "Fine",
                  accessor: "endTime",
                  width: 60,
                  Cell: (row) =>
                    `${moment.unix(row.original.endTime).format("HH:mm")}`,
                  Footer: (row) => {
                    const count = _(row.data).groupBy("endTime").size();
                    return (
                      <div className="assegnazioneCompleta">Cnt: {count}</div>
                    );
                  },
                  Filter: <></>,
                },
                {
                  Header: "Timbrate",
                  width: 120,
                  Cell: (row) => {
                    let orarioInizio = "-";
                    let orarioFine = "-";

                    if (
                      row.original.entrata &&
                      row.original.entrata.orarioDichiarato
                    ) {
                      orarioInizio = moment
                        .unix(row.original.entrata.orarioDichiarato)
                        .format("HH:mm");
                    }

                    if (
                      row.original.uscita &&
                      row.original.uscita.orarioDichiarato
                    ) {
                      orarioFine = moment
                        .unix(row.original.uscita.orarioDichiarato)
                        .format("HH:mm");
                    }

                    return orarioInizio + " -> " + orarioFine;
                  },
                  Filter: <></>,
                },
                {
                  Header: "Lotto",
                  accessor: "jsonExtra.jsonExtraPeriod.lot.name",
                  width: 80,
                  Footer: (row) => {
                    const count = _(row.data)
                      .groupBy("jsonExtra.jsonExtraPeriod.lot.name")
                      .size();
                    return (
                      <div className="assegnazioneCompleta">Cnt: {count}</div>
                    );
                  },
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some(
                      (f) =>
                        f.value ===
                        row._original.jsonExtra.jsonExtraPeriod.lot.name
                    );
                  },

                  Filter: ({ filter, onChange }) => {
                    const filterName =
                      "jsonExtra.jsonExtraPeriod.lot.description";
                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [{ filterName }])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                [filterName]: value,
                              },
                            },
                            () => onChange(value)
                          )
                        }
                      />
                    );
                  },
                },
                {
                  Header: "Valutazione",
                  accessor: "jsonExtra.rating",
                  width: 120,
                  Cell: (row) => (
                    <StarRatings
                      rating={row.value}
                      starRatedColor="yellow"
                      numberOfStars={5}
                      starDimension="15px"
                      starSpacing="3px"
                      name="rating"
                    />
                  ),
                  Filter: <></>,
                },
                {
                  Header: "Tipologia",
                  accessor: "jsonExtra.jsonExtraPeriod.type",
                  width: 100,
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some(
                      (f) =>
                        f.value === row._original.jsonExtra.jsonExtraPeriod.type
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName = "jsonExtra.jsonExtraPeriod.type";
                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [{ filterName }])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                [filterName]: value,
                              },
                            },
                            () => onChange(value)
                          )
                        }
                      />
                    );
                  },
                },
                {
                  Header: "Luogo",
                  accessor: "jsonExtra.jsonWorkPlace.description",
                  width: 200,
                  Footer: (row) => {
                    const count = _(row.data)
                      .groupBy("jsonExtra.jsonWorkPlace.description")
                      .size();
                    return (
                      <div className="assegnazioneCompleta">Cnt: {count}</div>
                    );
                  },
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some(
                      (f) =>
                        f.value ===
                        row._original.jsonExtra.jsonWorkPlace.description
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName = "jsonExtra.jsonWorkPlace.description";
                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [{ filterName }])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                [filterName]: value,
                              },
                            },
                            () => onChange(value)
                          )
                        }
                      />
                    );
                  },
                },
                {
                  Header: "Produzione",
                  width: 300,
                  accessor: "jsonExtra.jsonExtraPeriod.production.name",
                  Footer: (row) => {
                    const count = _(row.data)
                      .groupBy("jsonExtra.jsonExtraPeriod.production.name")
                      .size();
                    return (
                      <div className="assegnazioneCompleta">Cnt: {count}</div>
                    );
                  },
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some(
                      (f) =>
                        f.value ===
                        row._original.jsonExtra.jsonExtraPeriod.production.name
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName =
                      "jsonExtra.jsonExtraPeriod.production.name";
                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [{ filterName }])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                [filterName]: value,
                              },
                            },
                            () => onChange(value)
                          )
                        }
                      />
                    );
                  },
                },
                {
                  Header: "Ore contratto",
                  accessor: "jsonWorker.oreContratto",
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some(
                      (f) => f.value === row._original.jsonWorker.oreContratto
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName = "jsonWorker.oreContratto";
                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [{ filterName }])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                [filterName]: value,
                              },
                            },
                            () => onChange(value)
                          )
                        }
                      />
                    );
                  },
                },
                {
                  Header: "Ore",
                  id: "Ore",
                  width: 100,
                  Filter: <></>,
                  accessor: (row) => {
                    const start = moment.unix(row.startTime);
                    const end = moment.unix(row.endTime);
                    var duration = moment.duration(end.diff(start));
                    var hours = duration.asHours();
                    return hours;
                  },
                  Footer: (row) => {
                    let oreTotali = 0;
                    _.map(row.data, (d) => {
                      const start = moment.unix(d._original.startTime);
                      const end = moment.unix(d._original.endTime);
                      var duration = moment.duration(end.diff(start));
                      var hours = duration.asHours();
                      oreTotali = oreTotali + hours;
                    });

                    return (
                      <div className="assegnazioneCompleta">
                        Tot: {oreTotali.toLocaleString()}
                      </div>
                    );
                  },
                },
                {
                  Header: "Ordine",
                  accessor: "order.number",
                  width: 80,
                  Footer: (row) => {
                    const count = _(row.data).groupBy("order.number").size();
                    return (
                      <div className="assegnazioneCompleta">Cnt: {count}</div>
                    );
                  },
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some(
                      (f) => f.value === row._original.order.number
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName = "order.number";
                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [{ filterName }])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                [filterName]: value,
                              },
                            },
                            () => onChange(value)
                          )
                        }
                      />
                    );
                  },
                },
                {
                  Header: "Servizio",
                  accessor: "jsonExtra.jsonArticle.description",
                  width: 100,
                  Footer: (row) => {
                    const count = _(row.data)
                      .groupBy("jsonExtra.jsonArticle.description")
                      .size();
                    return (
                      <div className="assegnazioneCompleta">Cnt: {count}</div>
                    );
                  },
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some(
                      (f) =>
                        f.value ===
                        row._original.jsonExtra.jsonArticle.description
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName = "jsonExtra.jsonArticle.description";
                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [{ filterName }])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                [filterName]: value,
                              },
                            },
                            () => onChange(value)
                          )
                        }
                      />
                    );
                  },
                },
                {
                  Header: "Stato",
                  accessor: "state",
                  width: 160,
                  Cell: (cellInfo) => {
                    const state = cellInfo.original.state.toLowerCase();
                    switch (state) {
                      case "assigned":
                        return (
                          <div className={"ordineApprovato"}>ASSEGNATO</div>
                        );
                      default:
                        return (
                          <div className={"ordineRifiutato"}>NON ASSEGNATO</div>
                        );
                    }
                  },

                  Filter: <></>,
                },

                {
                  Header: "Contratto",
                  accessor:
                    "jsonExtra.jsonExtraPeriod.production.contract.description",
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some(
                      (f) =>
                        f.value ===
                        row._original.jsonExtra.jsonExtraPeriod.production
                          .contract.description
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName =
                      "jsonExtra.jsonExtraPeriod.production.contract.description";
                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [{ filterName }])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                [filterName]: value,
                              },
                            },
                            () => onChange(value)
                          )
                        }
                      />
                    );
                  },
                },
              ]}
              // defaultPageSize={this.props.data.length < 250 ? this.props.data.length : 250}
              pageSizeOptions={[5, 10, 20, 50, 100, 150, 200, 250]}
              showPaginationBottom={true}
              className="-striped -highlight"
              nextText={"Avanti"}
              previousText={"Indietro"}
              pageText={"Pagina"}
              ofText={"di"}
              rowsText={"elementi"}
              noDataText={"Nessun dato"}
              loadingText={"Caricamento in corso"}
              loading={false}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const data = {
    data: get(state, "raiAnalyticsSoci.data", []),
    loading: get(state, "raiAnalyticsSoci.loading", false),
  };

  return data;
};

export default connect(mapStateToProps, () => ({}))(StatisticheSocioComponent);
