import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import DateRangePickerWrapper from "components/DateRangePicker/DateRangePickerWrapper";
import Input from "reactstrap/es/Input";
import moment from "moment";

import SchedaSocioComponent from "./components/schedaSocio/schedaSocio.component";
import SociLiberi from "./components/sociLiberi/sociLiberi.component";
import StatisticheSociComponent from "./components/statisticheSoci/statisticheSocio.component";

import * as Commands from "./redux/analyticsSoci.commands";
import AdminWrapper from "components/AdminWrapper/AdminWrapper";

class AnalitycsSociComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new moment(),
      endDate: new moment(),
      fullText: "",
      showExportDropDown: false,
    };
  }

  onDatesChanged(startDate, endDate) {
    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        //this.caricaDati(startDate, endDate, this.state.fullText)
      }
    );
  }

  changeActiveTab = (e, tabName) => {
    e.preventDefault();
    this.setState({
      tabName,
    });
  };

  downloadRptOreLavoratoriDettagliato = async (startDate, endDate) => {
    const startDateClone = startDate.clone();
    const endDateClone = endDate.clone();
    this.setState({ loading: true });
    const res = await Commands.RptOreLavoratoriDettagliato(
      moment.unix(startDateClone).unix() / 1000,
      moment.unix(endDateClone).unix() / 1000
    );
    this.setState({ loading: false, loaded: true });

    if (res.error) {
      return;
    }

    const element = document.createElement("a");
    element.target = "_blank";
    element.href = res.data;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  toggleShowExportDropDown = () =>
    this.setState({ showExportDropDown: !this.state.showExportDropDown });

  render() {
    const content = (
      <>
        {/*Contenuto pagina*/}

        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  {/*Filtri*/}
                  <Row>
                    <Col xs={10} sm={10} lg={4}>
                      <DateRangePickerWrapper
                        disabled={false}
                        initialStartDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        initialEndDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        small
                        enableOutsideDays={true}
                        startDatePlaceholderText={"Data inizio"}
                        endDatePlaceholderText={"Data fine"}
                        showDefaultInputIcon
                        isOutsideRange={() => false}
                        onChange={(startDate, endDate) =>
                          this.onDatesChanged(startDate, endDate)
                        }
                      />
                    </Col>
                    <Col xs={10} lg={4}>
                      <Input
                        onChange={(e) => {
                          this.setState({ fullText: e.target.value }, () => {
                            this.caricaDati(
                              this.state.startDate,
                              this.state.endDate,
                              this.state.fullText
                            );
                          });
                        }}
                        value={this.state.fullText}
                        placeHolder="Ricerca...."
                      />
                    </Col>
                    <Col xs={10} lg={4} className="d-flex justify-content-end">
                      <ButtonDropdown
                        isOpen={this.state.showExportDropDown}
                        toggle={this.toggleShowExportDropDown}
                      >
                        <DropdownToggle className="btn btn-primary" caret>
                          Reports
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => {}}>
                            Riepilogo data
                          </DropdownItem>
                          <DropdownItem disabled={true} onClick={""}>
                            Riepilogo socio
                          </DropdownItem>
                          <DropdownItem disabled={true} onClick={""}>
                            Riepilogo produzione
                          </DropdownItem>
                          <DropdownItem disabled={true} onClick={""}>
                            Dettaglio data
                          </DropdownItem>
                          <DropdownItem disabled={true} onClick={""}>
                            Dettaglio socio
                          </DropdownItem>
                          <DropdownItem disabled={true} onClick={""}>
                            Dettaglio produzione
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Card>
              <CardBody>
                <Nav className="nav-pills-info" pills>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      className={
                        this.state.horizontalTabs === "statisticheSoci"
                          ? "active"
                          : ""
                      }
                      onClick={(e) =>
                        this.changeActiveTab(e, "statisticheSoci")
                      }
                    >
                      Statistiche soci
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      className={
                        this.state.horizontalTabs === "sociLiberi"
                          ? "active"
                          : ""
                      }
                      onClick={(e) => this.changeActiveTab(e, "sociLiberi")}
                    >
                      Soci liberi
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>

            {this.state.tabName === "statisticheSoci" && (
              <StatisticheSociComponent {...this.state} />
            )}

            {this.state.tabName === "schedaSocio" && (
              <SchedaSocioComponent {...this.state} />
            )}

            {this.state.tabName === "sociLiberi" && (
              <SociLiberi {...this.state} />
            )}
          </Row>
        </div>
      </>
    );

    return <AdminWrapper children={content} />;
  }
}

const mapStateToProps = (state) => {
  //

  return {};
};

export default connect(mapStateToProps, () => ({}))(AnalitycsSociComponent);
