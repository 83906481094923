import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react plugin for creating vector maps

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

// core components
import { chartExample1 } from "variables/charts.jsx";
import AdminWrapper from "components/AdminWrapper/AdminWrapper";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
    };
  }

  setBgChartData = (name) => {
    this.setState({
      bigChartData: name,
    });
  };

  render() {
    const content = (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <h5 className="card-category">Totale ore</h5>
                      <CardTitle tag="h2">Performance</CardTitle>
                    </Col>
                    <Col sm="6">
                      <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                      >
                        <Button
                          color="info"
                          id="0"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data1",
                          })}
                          onClick={() => this.setBgChartData("data1")}
                        >
                          <input defaultChecked name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Ordini
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="1"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data2",
                          })}
                          onClick={() => this.setBgChartData("data2")}
                        >
                          <input name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Lavoratori
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={chartExample1[this.state.bigChartData]}
                      options={chartExample1.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-warning">
                        <i className="tim-icons icon-chat-33" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Ore odierne</p>
                        <CardTitle tag="h3">0</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-refresh-01" /> Ultimo
                    aggiornamento alle 10:00
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-primary">
                        <i className="tim-icons icon-shape-star" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Ore settimana</p>
                        <CardTitle tag="h3">0 +0%</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-refresh-01" /> Ultimo
                    aggiornamento alle 09:50
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-success">
                        <i className="tim-icons icon-single-02" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Lavoratori impegnati</p>
                        <CardTitle tag="h3">0</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-refresh-01" /> Ultimo
                    aggiornamento alle 10:00
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-danger">
                        <i className="tim-icons icon-molecule-40" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">
                          Servizi non completati nel mese
                        </p>
                        <CardTitle tag="h3">0</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-refresh-01" /> Ultimo
                    aggiornamento alle 09.45 hours
                  </div>
                </CardFooter>
              </Card>
            </Col>
            {/*                        <Col lg="4">*/}
            {/*                            <Card className="card-chart">*/}
            {/*                                <CardHeader>*/}
            {/*                                    <h5 className="card-category">Fatturato</h5>*/}
            {/*                                    <CardTitle tag="h3">*/}
            {/*                                        <i className="tim-icons icon-bell-55 text-primary"/>{" "}*/}
            {/*                                        763,215*/}
            {/*                                    </CardTitle>*/}
            {/*                                </CardHeader>*/}
            {/*                                <CardBody>*/}
            {/*                                    <div className="chart-area">*/}
            {/*                                        <Line*/}
            {/*                                            data={chartExample2.data}*/}
            {/*                                            options={chartExample2.options}*/}
            {/*                                        />*/}
            {/*                                    </div>*/}
            {/*                                </CardBody>*/}
            {/*                            </Card>*/}
            {/*                        </Col>*/}
            {/*                        <Col lg="4">*/}
            {/*                            <Card className="card-chart">*/}
            {/*                                <CardHeader>*/}
            {/*                                    <h5 className="card-category">Stipendi mensili e contributi</h5>*/}
            {/*                                    <CardTitle tag="h3">*/}
            {/*<Row>*/}
            {/*                                        <Col xs={6}>*/}
            {/*                                            <i className="tim-icons icon-delivery-fast text-info"/>{" "}*/}
            {/*                                            440.000€*/}
            {/*                                        </Col>*/}
            {/*                                        <Col xs={6}>*/}
            {/*                                            <i className="tim-icons icon-delivery-fast text-info"/>{" "}*/}
            {/*                                            367.000€*/}
            {/*                                        </Col>*/}
            {/*</Row>*/}
            {/*                                    </CardTitle>*/}
            {/*                                </CardHeader>*/}
            {/*                                <CardBody>*/}
            {/*                                    <div className="chart-area">*/}
            {/*                                        <Bar*/}
            {/*                                            data={chartExample3.data}*/}
            {/*                                            options={chartExample3.options}*/}
            {/*                                        />*/}
            {/*                                    </div>*/}
            {/*                                </CardBody>*/}
            {/*                            </Card>*/}
            {/*                        </Col>*/}
            {/*                        <Col lg="4">*/}
            {/*                            <Card className="card-chart">*/}
            {/*                                <CardHeader>*/}
            {/*                                    <h5 className="card-category">Servizi erogati</h5>*/}
            {/*                                    <CardTitle tag="h3">*/}
            {/*                                        <i className="tim-icons icon-send text-success"/> 18.000*/}
            {/*                                    </CardTitle>*/}
            {/*                                </CardHeader>*/}
            {/*                                <CardBody>*/}
            {/*                                    <div className="chart-area">*/}
            {/*                                        <Line*/}
            {/*                                            data={chartExample4.data}*/}
            {/*                                            options={chartExample4.options}*/}
            {/*                                        />*/}
            {/*                                    </div>*/}
            {/*                                </CardBody>*/}
            {/*                            </Card>*/}
            {/*                        </Col>*/}
          </Row>
          {/*<Row>*/}
          {/*    <Col lg="5">*/}
          {/*        <Card className="card-tasks">*/}
          {/*            <CardHeader>*/}
          {/*                <h6 className="title d-inline">Tasks(5)</h6>*/}
          {/*                <p className="card-category d-inline">today</p>*/}
          {/*                <UncontrolledDropdown>*/}
          {/*                    <DropdownToggle*/}
          {/*                        caret*/}
          {/*                        className="btn-icon"*/}
          {/*                        color="link"*/}
          {/*                        data-toggle="dropdown"*/}
          {/*                        type="button"*/}
          {/*                    >*/}
          {/*                        <i className="tim-icons icon-settings-gear-63"/>*/}
          {/*                    </DropdownToggle>*/}
          {/*                    <DropdownMenu right>*/}
          {/*                        <DropdownItem*/}
          {/*                            href="#pablo"*/}
          {/*                            onClick={e => e.preventDefault()}*/}
          {/*                        >*/}
          {/*                            Action*/}
          {/*                        </DropdownItem>*/}
          {/*                        <DropdownItem*/}
          {/*                            href="#pablo"*/}
          {/*                            onClick={e => e.preventDefault()}*/}
          {/*                        >*/}
          {/*                            Another action*/}
          {/*                        </DropdownItem>*/}
          {/*                        <DropdownItem*/}
          {/*                            href="#pablo"*/}
          {/*                            onClick={e => e.preventDefault()}*/}
          {/*                        >*/}
          {/*                            Something else*/}
          {/*                        </DropdownItem>*/}
          {/*                    </DropdownMenu>*/}
          {/*                </UncontrolledDropdown>*/}
          {/*            </CardHeader>*/}
          {/*            <CardBody>*/}
          {/*                <div className="table-full-width table-responsive">*/}
          {/*                    <Table>*/}
          {/*                        <tbody>*/}
          {/*                        <tr>*/}
          {/*                            <td>*/}
          {/*                                <FormGroup check>*/}
          {/*                                    <Label check>*/}
          {/*                                        <Input defaultValue="" type="checkbox"/>*/}
          {/*                                        <span className="form-check-sign">*/}
          {/*              <span className="check"/>*/}
          {/*            </span>*/}
          {/*                                    </Label>*/}
          {/*                                </FormGroup>*/}
          {/*                            </td>*/}
          {/*                            <td>*/}
          {/*                                <p className="title">Update the Documentation</p>*/}
          {/*                                <p className="text-muted">*/}
          {/*                                    Dwuamish Head, Seattle, WA 8:47 AM*/}
          {/*                                </p>*/}
          {/*                            </td>*/}
          {/*                            <td className="td-actions text-right">*/}
          {/*                                <Button*/}
          {/*                                    color="link"*/}
          {/*                                    id="tooltip786630859"*/}
          {/*                                    title=""*/}
          {/*                                    type="button"*/}
          {/*                                >*/}
          {/*                                    <i className="tim-icons icon-pencil"/>*/}
          {/*                                </Button>*/}
          {/*                                <UncontrolledTooltip*/}
          {/*                                    delay={0}*/}
          {/*                                    target="tooltip786630859"*/}
          {/*                                >*/}
          {/*                                    Edit Task*/}
          {/*                                </UncontrolledTooltip>*/}
          {/*                            </td>*/}
          {/*                        </tr>*/}
          {/*                        <tr>*/}
          {/*                            <td>*/}
          {/*                                <FormGroup check>*/}
          {/*                                    <Label check>*/}
          {/*                                        <Input*/}
          {/*                                            defaultChecked*/}
          {/*                                            defaultValue=""*/}
          {/*                                            type="checkbox"*/}
          {/*                                        />*/}
          {/*                                        <span className="form-check-sign">*/}
          {/*              <span className="check"/>*/}
          {/*            </span>*/}
          {/*                                    </Label>*/}
          {/*                                </FormGroup>*/}
          {/*                            </td>*/}
          {/*                            <td>*/}
          {/*                                <p className="title">GDPR Compliance</p>*/}
          {/*                                <p className="text-muted">*/}
          {/*                                    The GDPR is a regulation that requires businesses*/}
          {/*                                    to protect the personal data and privacy of Europe*/}
          {/*                                    citizens for transactions that occur within EU*/}
          {/*                                    member states.*/}
          {/*                                </p>*/}
          {/*                            </td>*/}
          {/*                            <td className="td-actions text-right">*/}
          {/*                                <Button*/}
          {/*                                    color="link"*/}
          {/*                                    id="tooltip155151810"*/}
          {/*                                    title=""*/}
          {/*                                    type="button"*/}
          {/*                                >*/}
          {/*                                    <i className="tim-icons icon-pencil"/>*/}
          {/*                                </Button>*/}
          {/*                                <UncontrolledTooltip*/}
          {/*                                    delay={0}*/}
          {/*                                    target="tooltip155151810"*/}
          {/*                                >*/}
          {/*                                    Edit Task*/}
          {/*                                </UncontrolledTooltip>*/}
          {/*                            </td>*/}
          {/*                        </tr>*/}
          {/*                        <tr>*/}
          {/*                            <td>*/}
          {/*                                <FormGroup check>*/}
          {/*                                    <Label check>*/}
          {/*                                        <Input defaultValue="" type="checkbox"/>*/}
          {/*                                        <span className="form-check-sign">*/}
          {/*              <span className="check"/>*/}
          {/*            </span>*/}
          {/*                                    </Label>*/}
          {/*                                </FormGroup>*/}
          {/*                            </td>*/}
          {/*                            <td>*/}
          {/*                                <p className="title">Solve the issues</p>*/}
          {/*                                <p className="text-muted">*/}
          {/*                                    Fifty percent of all respondents said they would*/}
          {/*                                    be more likely to shop at a company*/}
          {/*                                </p>*/}
          {/*                            </td>*/}
          {/*                            <td className="td-actions text-right">*/}
          {/*                                <Button*/}
          {/*                                    color="link"*/}
          {/*                                    id="tooltip199559448"*/}
          {/*                                    title=""*/}
          {/*                                    type="button"*/}
          {/*                                >*/}
          {/*                                    <i className="tim-icons icon-pencil"/>*/}
          {/*                                </Button>*/}
          {/*                                <UncontrolledTooltip*/}
          {/*                                    delay={0}*/}
          {/*                                    target="tooltip199559448"*/}
          {/*                                >*/}
          {/*                                    Edit Task*/}
          {/*                                </UncontrolledTooltip>*/}
          {/*                            </td>*/}
          {/*                        </tr>*/}
          {/*                        <tr>*/}
          {/*                            <td>*/}
          {/*                                <FormGroup check>*/}
          {/*                                    <Label check>*/}
          {/*                                        <Input defaultValue="" type="checkbox"/>*/}
          {/*                                        <span className="form-check-sign">*/}
          {/*              <span className="check"/>*/}
          {/*            </span>*/}
          {/*                                    </Label>*/}
          {/*                                </FormGroup>*/}
          {/*                            </td>*/}
          {/*                            <td>*/}
          {/*                                <p className="title">Release v2.0.0</p>*/}
          {/*                                <p className="text-muted">*/}
          {/*                                    Ra Ave SW, Seattle, WA 98116, SUA 11:19 AM*/}
          {/*                                </p>*/}
          {/*                            </td>*/}
          {/*                            <td className="td-actions text-right">*/}
          {/*                                <Button*/}
          {/*                                    color="link"*/}
          {/*                                    id="tooltip989676508"*/}
          {/*                                    title=""*/}
          {/*                                    type="button"*/}
          {/*                                >*/}
          {/*                                    <i className="tim-icons icon-pencil"/>*/}
          {/*                                </Button>*/}
          {/*                                <UncontrolledTooltip*/}
          {/*                                    delay={0}*/}
          {/*                                    target="tooltip989676508"*/}
          {/*                                >*/}
          {/*                                    Edit Task*/}
          {/*                                </UncontrolledTooltip>*/}
          {/*                            </td>*/}
          {/*                        </tr>*/}
          {/*                        <tr>*/}
          {/*                            <td>*/}
          {/*                                <FormGroup check>*/}
          {/*                                    <Label check>*/}
          {/*                                        <Input defaultValue="" type="checkbox"/>*/}
          {/*                                        <span className="form-check-sign">*/}
          {/*              <span className="check"/>*/}
          {/*            </span>*/}
          {/*                                    </Label>*/}
          {/*                                </FormGroup>*/}
          {/*                            </td>*/}
          {/*                            <td>*/}
          {/*                                <p className="title">Export the processed files</p>*/}
          {/*                                <p className="text-muted">*/}
          {/*                                    The report also shows that consumers will not*/}
          {/*                                    easily forgive a company once a breach exposing*/}
          {/*                                    their personal data occurs.*/}
          {/*                                </p>*/}
          {/*                            </td>*/}
          {/*                            <td className="td-actions text-right">*/}
          {/*                                <Button*/}
          {/*                                    color="link"*/}
          {/*                                    id="tooltip557118868"*/}
          {/*                                    title=""*/}
          {/*                                    type="button"*/}
          {/*                                >*/}
          {/*                                    <i className="tim-icons icon-pencil"/>*/}
          {/*                                </Button>*/}
          {/*                                <UncontrolledTooltip*/}
          {/*                                    delay={0}*/}
          {/*                                    target="tooltip557118868"*/}
          {/*                                >*/}
          {/*                                    Edit Task*/}
          {/*                                </UncontrolledTooltip>*/}
          {/*                            </td>*/}
          {/*                        </tr>*/}
          {/*                        <tr>*/}
          {/*                            <td>*/}
          {/*                                <FormGroup check>*/}
          {/*                                    <Label check>*/}
          {/*                                        <Input defaultValue="" type="checkbox"/>*/}
          {/*                                        <span className="form-check-sign">*/}
          {/*              <span className="check"/>*/}
          {/*            </span>*/}
          {/*                                    </Label>*/}
          {/*                                </FormGroup>*/}
          {/*                            </td>*/}
          {/*                            <td>*/}
          {/*                                <p className="title">Arival at export process</p>*/}
          {/*                                <p className="text-muted">*/}
          {/*                                    Capitol Hill, Seattle, WA 12:34 AM*/}
          {/*                                </p>*/}
          {/*                            </td>*/}
          {/*                            <td className="td-actions text-right">*/}
          {/*                                <Button*/}
          {/*                                    color="link"*/}
          {/*                                    id="tooltip143185858"*/}
          {/*                                    title=""*/}
          {/*                                    type="button"*/}
          {/*                                >*/}
          {/*                                    <i className="tim-icons icon-pencil"/>*/}
          {/*                                </Button>*/}
          {/*                                <UncontrolledTooltip*/}
          {/*                                    delay={0}*/}
          {/*                                    target="tooltip143185858"*/}
          {/*                                >*/}
          {/*                                    Edit Task*/}
          {/*                                </UncontrolledTooltip>*/}
          {/*                            </td>*/}
          {/*                        </tr>*/}
          {/*                        </tbody>*/}
          {/*                    </Table>*/}
          {/*                </div>*/}
          {/*            </CardBody>*/}
          {/*        </Card>*/}
          {/*    </Col>*/}
          {/*    <Col lg="7">*/}
          {/*        <Card>*/}
          {/*            <CardHeader>*/}
          {/*                <div className="tools float-right">*/}
          {/*                    <UncontrolledDropdown>*/}
          {/*                        <DropdownToggle*/}
          {/*                            caret*/}
          {/*                            className="btn-icon"*/}
          {/*                            color="link"*/}
          {/*                            data-toggle="dropdown"*/}
          {/*                            type="button"*/}
          {/*                        >*/}
          {/*                            <i className="tim-icons icon-settings-gear-63"/>*/}
          {/*                        </DropdownToggle>*/}
          {/*                        <DropdownMenu right>*/}
          {/*                            <DropdownItem*/}
          {/*                                href="#pablo"*/}
          {/*                                onClick={e => e.preventDefault()}*/}
          {/*                            >*/}
          {/*                                Action*/}
          {/*                            </DropdownItem>*/}
          {/*                            <DropdownItem*/}
          {/*                                href="#pablo"*/}
          {/*                                onClick={e => e.preventDefault()}*/}
          {/*                            >*/}
          {/*                                Another action*/}
          {/*                            </DropdownItem>*/}
          {/*                            <DropdownItem*/}
          {/*                                href="#pablo"*/}
          {/*                                onClick={e => e.preventDefault()}*/}
          {/*                            >*/}
          {/*                                Something else*/}
          {/*                            </DropdownItem>*/}
          {/*                            <DropdownItem*/}
          {/*                                className="text-danger"*/}
          {/*                                href="#pablo"*/}
          {/*                                onClick={e => e.preventDefault()}*/}
          {/*                            >*/}
          {/*                                Remove Data*/}
          {/*                            </DropdownItem>*/}
          {/*                        </DropdownMenu>*/}
          {/*                    </UncontrolledDropdown>*/}
          {/*                </div>*/}
          {/*                <CardTitle tag="h5">Valutazione lavoratori</CardTitle>*/}
          {/*            </CardHeader>*/}
          {/*            <CardBody>*/}
          {/*                <Table responsive>*/}
          {/*                    <thead className="text-primary">*/}
          {/*                    <tr>*/}
          {/*                        <th className="text-center">#</th>*/}
          {/*                        <th>Name</th>*/}
          {/*                        <th>Job Position</th>*/}
          {/*                        <th>Milestone</th>*/}
          {/*                        <th className="text-right">Salary</th>*/}
          {/*                        <th className="text-right">Actions</th>*/}
          {/*                    </tr>*/}
          {/*                    </thead>*/}
          {/*                    <tbody>*/}
          {/*                    <tr>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="photo">*/}
          {/*                                <img*/}
          {/*                                    alt="..."*/}
          {/*                                    src={require("assets/img/tania.jpg")}*/}
          {/*                                />*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td>Tania Mike</td>*/}
          {/*                        <td>Develop</td>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="progress-container progress-sm">*/}
          {/*                                <Progress multi>*/}
          {/*                                    <span className="progress-value">25%</span>*/}
          {/*                                    <Progress bar max="100" value="25"/>*/}
          {/*                                </Progress>*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td className="text-right">€ 99,225</td>*/}
          {/*                        <td className="text-right">*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon btn-neutral"*/}
          {/*                                color="success"*/}
          {/*                                id="tooltip618296632"*/}
          {/*                                size="sm"*/}
          {/*                                title="Refresh"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-refresh-01"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip618296632"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon btn-neutral"*/}
          {/*                                color="danger"*/}
          {/*                                id="tooltip707467505"*/}
          {/*                                size="sm"*/}
          {/*                                title="Delete"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-simple-remove"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip707467505"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                        </td>*/}
          {/*                    </tr>*/}
          {/*                    <tr>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="photo">*/}
          {/*                                <img*/}
          {/*                                    alt="..."*/}
          {/*                                    src={require("assets/img/robi.jpg")}*/}
          {/*                                />*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td>John Doe</td>*/}
          {/*                        <td>CEO</td>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="progress-container progress-sm">*/}
          {/*                                <Progress multi>*/}
          {/*                                    <span className="progress-value">77%</span>*/}
          {/*                                    <Progress bar max="100" value="77"/>*/}
          {/*                                </Progress>*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td className="text-right">€ 89,241</td>*/}
          {/*                        <td className="text-right">*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon btn-neutral"*/}
          {/*                                color="success"*/}
          {/*                                id="tooltip216846074"*/}
          {/*                                size="sm"*/}
          {/*                                title="Refresh"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-refresh-01"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip216846074"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon btn-neutral"*/}
          {/*                                color="danger"*/}
          {/*                                id="tooltip391990405"*/}
          {/*                                size="sm"*/}
          {/*                                title="Delete"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-simple-remove"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip391990405"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                        </td>*/}
          {/*                    </tr>*/}
          {/*                    <tr>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="photo">*/}
          {/*                                <img*/}
          {/*                                    alt="..."*/}
          {/*                                    src={require("assets/img/lora.jpg")}*/}
          {/*                                />*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td>Alexa Mike</td>*/}
          {/*                        <td>Design</td>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="progress-container progress-sm">*/}
          {/*                                <Progress multi>*/}
          {/*                                    <span className="progress-value">41%</span>*/}
          {/*                                    <Progress bar max="100" value="41"/>*/}
          {/*                                </Progress>*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td className="text-right">€ 92,144</td>*/}
          {/*                        <td className="text-right">*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon btn-neutral"*/}
          {/*                                color="success"*/}
          {/*                                id="tooltip191500186"*/}
          {/*                                size="sm"*/}
          {/*                                title="Refresh"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-refresh-01"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip191500186"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon btn-neutral"*/}
          {/*                                color="danger"*/}
          {/*                                id="tooltip320351170"*/}
          {/*                                size="sm"*/}
          {/*                                title="Delete"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-simple-remove"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip320351170"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                        </td>*/}
          {/*                    </tr>*/}
          {/*                    <tr>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="photo">*/}
          {/*                                <img*/}
          {/*                                    alt="..."*/}
          {/*                                    src={require("assets/img/jana.jpg")}*/}
          {/*                                />*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td>Jana Monday</td>*/}
          {/*                        <td>Marketing</td>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="progress-container progress-sm">*/}
          {/*                                <Progress multi>*/}
          {/*                                    <span className="progress-value">50%</span>*/}
          {/*                                    <Progress bar max="100" value="50"/>*/}
          {/*                                </Progress>*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td className="text-right">€ 49,990</td>*/}
          {/*                        <td className="text-right">*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon"*/}
          {/*                                color="success"*/}
          {/*                                id="tooltip345411997"*/}
          {/*                                size="sm"*/}
          {/*                                title="Refresh"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-refresh-01"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip345411997"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon"*/}
          {/*                                color="danger"*/}
          {/*                                id="tooltip601343171"*/}
          {/*                                size="sm"*/}
          {/*                                title="Delete"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-simple-remove"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip601343171"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                        </td>*/}
          {/*                    </tr>*/}
          {/*                    <tr>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="photo">*/}
          {/*                                <img*/}
          {/*                                    alt="..."*/}
          {/*                                    src={require("assets/img/mike.jpg")}*/}
          {/*                                />*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td>Paul Dickens</td>*/}
          {/*                        <td>Develop</td>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="progress-container progress-sm">*/}
          {/*                                <Progress multi>*/}
          {/*                                    <span className="progress-value">100%</span>*/}
          {/*                                    <Progress bar max="100" value="100"/>*/}
          {/*                                </Progress>*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td className="text-right">€ 69,201</td>*/}
          {/*                        <td className="text-right">*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon"*/}
          {/*                                color="success"*/}
          {/*                                id="tooltip774891382"*/}
          {/*                                size="sm"*/}
          {/*                                title="Refresh"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-refresh-01"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip774891382"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon"*/}
          {/*                                color="danger"*/}
          {/*                                id="tooltip949929353"*/}
          {/*                                size="sm"*/}
          {/*                                title="Delete"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-simple-remove"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip949929353"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                        </td>*/}
          {/*                    </tr>*/}
          {/*                    <tr>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="photo">*/}
          {/*                                <img*/}
          {/*                                    alt="..."*/}
          {/*                                    src={require("assets/img/emilyz.jpg")}*/}
          {/*                                />*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td>Manuela Rico</td>*/}
          {/*                        <td>Manager</td>*/}
          {/*                        <td className="text-center">*/}
          {/*                            <div className="progress-container progress-sm">*/}
          {/*                                <Progress multi>*/}
          {/*                                    <span className="progress-value">15%</span>*/}
          {/*                                    <Progress bar max="100" value="15"/>*/}
          {/*                                </Progress>*/}
          {/*                            </div>*/}
          {/*                        </td>*/}
          {/*                        <td className="text-right">€ 99,201</td>*/}
          {/*                        <td className="text-right">*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon"*/}
          {/*                                color="success"*/}
          {/*                                id="tooltip30547133"*/}
          {/*                                size="sm"*/}
          {/*                                title="Refresh"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-refresh-01"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip30547133"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                            <Button*/}
          {/*                                className="btn-link btn-icon"*/}
          {/*                                color="danger"*/}
          {/*                                id="tooltip156899243"*/}
          {/*                                size="sm"*/}
          {/*                                title="Delete"*/}
          {/*                                type="button"*/}
          {/*                            >*/}
          {/*                                <i className="tim-icons icon-simple-remove"/>*/}
          {/*                            </Button>*/}
          {/*                            <UncontrolledTooltip*/}
          {/*                                delay={0}*/}
          {/*                                target="tooltip156899243"*/}
          {/*                            >*/}
          {/*                                Tooltip on top*/}
          {/*                            </UncontrolledTooltip>*/}
          {/*                        </td>*/}
          {/*                    </tr>*/}
          {/*                    </tbody>*/}
          {/*                </Table>*/}
          {/*            </CardBody>*/}
          {/*        </Card>*/}
          {/*    </Col>*/}
          {/*    <Col lg="12">*/}
          {/*        <Card>*/}
          {/*            <CardHeader>*/}
          {/*                <CardTitle tag="h4">Global Sales by Top Locations</CardTitle>*/}
          {/*                <p className="card-category">*/}
          {/*                    All products that were shipped*/}
          {/*                </p>*/}
          {/*            </CardHeader>*/}
          {/*            <CardBody>*/}
          {/*                <Row>*/}
          {/*                    <Col md="6">*/}
          {/*                        <Table responsive>*/}
          {/*                            <tbody>*/}
          {/*                            <tr>*/}
          {/*                                <td>*/}
          {/*                                    <div className="flag">*/}
          {/*                                        <img*/}
          {/*                                            alt="..."*/}
          {/*                                            src={require("assets/img/US.png")}*/}
          {/*                                        />*/}
          {/*                                    </div>*/}
          {/*                                </td>*/}
          {/*                                <td>USA</td>*/}
          {/*                                <td className="text-right">2.920</td>*/}
          {/*                                <td className="text-right">53.23%</td>*/}
          {/*                            </tr>*/}
          {/*                            <tr>*/}
          {/*                                <td>*/}
          {/*                                    <div className="flag">*/}
          {/*                                        <img*/}
          {/*                                            alt="..."*/}
          {/*                                            src={require("assets/img/DE.png")}*/}
          {/*                                        />*/}
          {/*                                    </div>*/}
          {/*                                </td>*/}
          {/*                                <td>Germany</td>*/}
          {/*                                <td className="text-right">1.300</td>*/}
          {/*                                <td className="text-right">20.43%</td>*/}
          {/*                            </tr>*/}
          {/*                            <tr>*/}
          {/*                                <td>*/}
          {/*                                    <div className="flag">*/}
          {/*                                        <img*/}
          {/*                                            alt="..."*/}
          {/*                                            src={require("assets/img/AU.png")}*/}
          {/*                                        />*/}
          {/*                                    </div>*/}
          {/*                                </td>*/}
          {/*                                <td>Australia</td>*/}
          {/*                                <td className="text-right">760</td>*/}
          {/*                                <td className="text-right">10.35%</td>*/}
          {/*                            </tr>*/}
          {/*                            <tr>*/}
          {/*                                <td>*/}
          {/*                                    <div className="flag">*/}
          {/*                                        <img*/}
          {/*                                            alt="..."*/}
          {/*                                            src={require("assets/img/GB.png")}*/}
          {/*                                        />*/}
          {/*                                    </div>*/}
          {/*                                </td>*/}
          {/*                                <td>United Kingdom</td>*/}
          {/*                                <td className="text-right">690</td>*/}
          {/*                                <td className="text-right">7.87%</td>*/}
          {/*                            </tr>*/}
          {/*                            <tr>*/}
          {/*                                <td>*/}
          {/*                                    <div className="flag">*/}
          {/*                                        <img*/}
          {/*                                            alt="..."*/}
          {/*                                            src={require("assets/img/RO.png")}*/}
          {/*                                        />*/}
          {/*                                    </div>*/}
          {/*                                </td>*/}
          {/*                                <td>Romania</td>*/}
          {/*                                <td className="text-right">600</td>*/}
          {/*                                <td className="text-right">5.94%</td>*/}
          {/*                            </tr>*/}
          {/*                            <tr>*/}
          {/*                                <td>*/}
          {/*                                    <div className="flag">*/}
          {/*                                        <img*/}
          {/*                                            alt="..."*/}
          {/*                                            src={require("assets/img/BR.png")}*/}
          {/*                                        />*/}
          {/*                                    </div>*/}
          {/*                                </td>*/}
          {/*                                <td>Brasil</td>*/}
          {/*                                <td className="text-right">550</td>*/}
          {/*                                <td className="text-right">4.34%</td>*/}
          {/*                            </tr>*/}
          {/*                            </tbody>*/}
          {/*                        </Table>*/}
          {/*                    </Col>*/}
          {/*                    <Col className="ml-auto mr-auto" md="6">*/}
          {/*                        <VectorMap*/}
          {/*                            map={"world_mill"}*/}
          {/*                            backgroundColor="transparent"*/}
          {/*                            zoomOnScroll={false}*/}
          {/*                            containerStyle={{*/}
          {/*                                width: "100%",*/}
          {/*                                height: "300px"*/}
          {/*                            }}*/}
          {/*                            regionStyle={{*/}
          {/*                                initial: {*/}
          {/*                                    fill: "#e4e4e4",*/}
          {/*                                    "fill-opacity": 0.9,*/}
          {/*                                    stroke: "none",*/}
          {/*                                    "stroke-width": 0,*/}
          {/*                                    "stroke-opacity": 0*/}
          {/*                                }*/}
          {/*                            }}*/}
          {/*                            series={{*/}
          {/*                                regions: [*/}
          {/*                                    {*/}
          {/*                                        values: mapData,*/}
          {/*                                        scale: ["#AAAAAA", "#444444"],*/}
          {/*                                        normalizeFunction: "polynomial"*/}
          {/*                                    }*/}
          {/*                                ]*/}
          {/*                            }}*/}
          {/*                        />*/}
          {/*                    </Col>*/}
          {/*                </Row>*/}
          {/*            </CardBody>*/}
          {/*        </Card>*/}
          {/*    </Col>*/}
          {/*</Row>*/}
        </div>
      </>
    );

    return <AdminWrapper children={content} />;
  }
}

export default Dashboard;
