import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Input,
    Row,
    FormGroup,
    Label,
    Col
} from "reactstrap";
import React, {useState, useEffect, useRef} from "react";
import {CSSTransition} from "react-transition-group";
import Select from "react-select";
import Datetime from 'react-datetime';
import moment from 'moment';
import * as Commands from '../redux/ordini.commands'
import SignaturePad from 'react-signature-pad-wrapper'
import StarRatings from 'react-star-ratings';

import 'react-day-picker/lib/style.css';
import * as OrdiniCommands from "views/rai/ordini/redux/ordini.commands";
import _ from 'lodash';
import {
    GetUsersForRoleAndSector,
    RUOLO_FIRMATARIO_STATINI_RAI
} from "views/rai/utenti/components/costants";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";


function ModalOrariSingolo(props) {
    const [selectedAss, setSelectedAss] = useState(props.ass);
    const [rating, setRating] = useState(-1);

    return (
        <Modal isOpen={props.showModalOrari} toggle={props.toggleModalOrari} modalClassName="modal-black">
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggleModalOrari}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica orario</h6>
            </div>
            <ModalBody>
                <FormGroup className={"row"}>
                    <Col xs={3}><Label className="col-form-label" for="lotto">Ora inizio</Label></Col>
                    <Col xs={9}>
                        <Datetime
                            className={"sparkleTimePicker"}
                            dateFormat={false}
                            timeFormat="HH:mm"
                            inputProps={{
                                className: "form-control",
                                placeholder: "Time picker"
                            }}
                            onChange={startTime => {
                                if (typeof startTime === 'object') {
                                    setSelectedAss({
                                        ...selectedAss,
                                        startTime: startTime.unix()
                                    })
                                }
                            }}

                            value={moment.unix(selectedAss.startTime)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup className={"row"}>
                    <Col xs={3}><Label className="col-form-label" for="lotto">Ora fine</Label></Col>

                    <Col xs={9}>
                        <Datetime
                            className={"sparkleTimePicker"}
                            dateFormat={false}
                            timeFormat="HH:mm"
                            inputProps={{
                                className: "form-control",
                                placeholder: "Time picker"
                            }}
                            onChange={endTime => {
                                if (typeof endTime === 'object') {
                                    setSelectedAss({
                                        ...selectedAss,
                                        endTime: endTime.unix()
                                    })
                                }
                            }}
                            value={moment.unix(selectedAss.endTime)}
                        />
                    </Col>
                </FormGroup>
                <StarRatings
                    rating={rating}
                    starRatedColor="yellow"
                    changeRating={setRating}
                    numberOfStars={5}
                    starDimension="30px"
                    name='rating'
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.toggleModalOrari}>Annulla</Button>
                <Button color="primary" onClick={() => {
                    props.toggleModalOrari();
                    props.cbFn(selectedAss, rating);
                }}>Conferma</Button>
            </ModalFooter>
        </Modal>
    );
}

function ModalOrariTutti(props) {
    const [startTime, setStartTime] = useState(moment.unix(props.ordine.periods[0].startTime));
    const [endTime, setEndTime] = useState(moment.unix(props.ordine.periods[0].endTime));

    return (
        <Modal isOpen={props.showModalOrariTutti} toggle={props.toggleModalOrariTutti} modalClassName="modal-black">
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggleModalOrariTutti}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica orario</h6>
            </div>
            <ModalBody>
                <p>Modifica l'orario di lavoro a tutti i prestatori di servizio</p>
                <FormGroup className={"row"}>
                    <Col xs={3}><Label className="col-form-label" for="lotto">Ora inizio</Label></Col>

                    <Col xs={9}>
                        <Datetime
                            className={"sparkleTimePicker"}
                            dateFormat={false}
                            timeFormat="HH:mm"
                            inputProps={{
                                className: "form-control",
                                placeholder: "Time picker"
                            }}
                            onChange={startTime => {
                                if (typeof startTime === 'object') {
                                    setStartTime(startTime)
                                }
                            }}

                            value={startTime}

                        />
                    </Col>
                </FormGroup>

                <FormGroup className={"row"}>
                    <Col xs={3}><Label className="col-form-label" for="lotto">Ora fine</Label></Col>

                    <Col xs={9}>
                        <Datetime
                            className={"sparkleTimePicker"}
                            dateFormat={false}
                            timeFormat="HH:mm"
                            inputProps={{
                                className: "form-control",
                                placeholder: "Time picker"
                            }}
                            onChange={endTime => {
                                if (typeof endTime === 'object') {
                                    setEndTime(endTime)
                                }
                            }}
                            value={endTime}
                        />
                    </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.toggleModalOrariTutti}>Annulla</Button>
                <Button color="primary" onClick={() => {
                    props.cbFn(startTime.unix(), endTime.unix());
                    props.toggleModalOrariTutti();
                }}>Conferma</Button>
            </ModalFooter>
        </Modal>
    );
}

export default function FirmaOrdine(props) {
    const [ordineCaricato, setOrdineCaricato] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [submitErrorDescr, setSubmitErrorDescr] = useState("");
    const [loading, setLoading] = useState(false);
    const [assegnazioni, setAssegnazioni] = useState([]);
    const [erroreFetchOrdine] = useState(null);
    const [utenteFirmante, setUtenteFirmante] = useState();
    // const [utenteOperatore, setUtenteOperatore] = useState();
    const [rating, setRating] = useState(5);
    const [showModalOrari, setShowModalOrari] = useState(false);
    const [showModalOrariTutti, setShowModalOrariTutti] = useState(false);
    const utenti = GetUsersForRoleAndSector(props.ordine.sector.id, RUOLO_FIRMATARIO_STATINI_RAI);
    const [correttaEsecuzione, setCorrettaEsecuzione] = useState(true);
    const [note, setNote] = useState('');

    const signaturePad = useRef(null);

    const [selectedAss, setSelectedAss] = useState({});

    const toggleModalOrari = (ass) => {
        setSelectedAss(ass);
        setShowModalOrari(!showModalOrari);
    }

    const toggleModalOrariTutti = () => {
        setShowModalOrariTutti(!showModalOrariTutti);
    }
    const ottieniAssegnazioni = async () => {

        setOrdineCaricato(false);
        const res = await OrdiniCommands.OttieniOrdine(props.ordine.id);
        if (res.hasError) {
            setOrdineCaricato(true);
            setSubmitError(res.message);
            return;
        }

        res.data.assignments.map(ass => ass.rating = 5);

        aggiornaAssegnazioni(res.data.assignments);
        setOrdineCaricato(true);
    };

    const aggiornaAssegnazioni = (assegnazioni) => {
        setAssegnazioni(assegnazioni);
    };


    const aggiornaOrarioATutteLeAssegnazioni = (startTime, endTime) => {
        let tempAss = [...assegnazioni];
        assegnazioni.forEach(ass => {
            ass.startTime = startTime;
            ass.endTime = endTime;
        });

        setAssegnazioni(tempAss);
    };

    const aggiornaAssegnazione = (assegnazione, singleRating) => {
        const ass = [...assegnazioni];
        var indexCercato = -1;

        assegnazioni.forEach((ass, index) => {
            if (ass.id === assegnazione.id) {
                indexCercato = index;
            }
        });

        ass[indexCercato] = {...assegnazione};
        ass[indexCercato].rating = rating;

        if (singleRating) {
            ass[indexCercato].rating = singleRating;
        }

        aggiornaAssegnazioni(ass);
    };

    const firmaOrdine = async () => {
        setLoading(true)
        let res = await Commands.AnteprimaFirmaOrdine(props.ordine.id, assegnazioni, rating, utenteFirmante, signaturePad.current.toDataURL(), note, correttaEsecuzione);
        setLoading(false);
        if (res.hasError) {
            setSubmitError(true);
            setSubmitErrorDescr(res.message);
            return;
        }

        const doAction = await ShowConfirmationDialog({
            title: "Riepilogo",
            html: <div style={{textAlign: "justify"}}>
                {
                    res.data.straordinari.length === 0 ?
                        <>
                            Non verrà creato alcun straordinario.<br/>
                        </>
                        :
                        <>
                            Verranno creati i seguenti <b>straordinari</b>:
                            <ul style={{
                                listStyle: "none",
                                padding: 10,
                                color: 'black'
                            }}>{res.data.straordinari.map(period =>
                                <li style={{color: 'black', fontSize: 14}}>
                                    [<b>{period.articleName}</b>] Orario: <b
                                    style={{fontSize: 16}}>{moment.unix(period.startTime).format("HH:mm") + " -> " + moment.unix(period.endTime).format("HH:mm")}</b> Lavoratori: <b
                                    style={{fontSize: 16}}>{period.workers}</b>
                                </li>)}</ul>
                        </>
                }
                Procedere ?
            </div>,
            confirmBtnText: 'Si',
            canEscapeKeyCancel: false,
        });

        if (doAction.confirm) {
            // Firma qua
            setLoading(true)
            let res = await Commands.FirmaOrdine(props.ordine.id, assegnazioni, rating, utenteFirmante, signaturePad.current.toDataURL(), note, correttaEsecuzione);
            setLoading(false);
            if (res.hasError) {
                setSubmitError(true);
                setSubmitErrorDescr(res.message);
                return;
            }
            props.toggle(true);
        }
    };

    const changeRating = (newRating) => {
        setRating(newRating)
        let tempAss = [...assegnazioni];
        assegnazioni.forEach(ass => {
            ass.rating = newRating;
        });

        setAssegnazioni(tempAss);
    }

    const cambioCorrettaEsecuzione = async () => {
        const nextVal = !correttaEsecuzione;

        if (!nextVal) {
            // Primo dialog conferma
            const confirm = await ShowConfirmationDialog({
                title: 'Attenzione',
                text: `Confermi che il servizio NON è stato prestato secondo le modalità, le
                            caratteristiche qualitative, metodologiche e tecniche ?`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });

            if (confirm.confirm) {
                // Secondo dialog conferma
                const doubleConfirm = await ShowConfirmationDialog({
                    title: 'Attenzione',
                    text: `Stai confermando che il servizio NON è stato reso, vuoi procedere ?`,
                    confirmButtonText: 'Si!',
                    cancelButtonText: 'No',
                    canEscapeKeyCancel: true
                });

                if (doubleConfirm.confirm) {
                    setCorrettaEsecuzione(nextVal);
                    return;
                }
            }
        } else {
            setCorrettaEsecuzione(nextVal);
        }

    }


    useEffect(() => {
        // Carico dati
        if (props.ordine && !ordineCaricato) {
            ottieniAssegnazioni();
        }
        ;

        return () => {
            // Chiamati nel componentWillUnmount
        };
    });

    const getAssLabel = (ass) => { 

        if (ass.state !== 'assigned') return 'Non assegnato'

        let capoSquadra = _.get(ass, "jsonExtra.teamLeader", false);
        let teamLaderSign = "";

        if (capoSquadra) {
            teamLaderSign = " *"
        }  

        return  ass.jsonWorker.cognome + " " + ass.jsonWorker.nome + teamLaderSign; 
    }

    return (

        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Firma ordine</h6>

            </div>
            <ModalBody>

                {
                    !ordineCaricato &&
                    <div>Caricamento assegnazioni in corso... <i className="fa fa-spin fa-spinner"/></div>
                }

                {ordineCaricato && erroreFetchOrdine &&
                <>
                    <p className={"text-danger"}>Non è stato possibile firmare l'ordine</p>

                    <blockquote className={"blockquote blockquote-danger"}>
                        <p className="mb-0 text-danger">Dettaglio errore: {erroreFetchOrdine}</p>
                    </blockquote>
                    <Button onClick={() => ottieniAssegnazioni()}>Riprova</Button>
                </>
                }

                {ordineCaricato && !erroreFetchOrdine &&
                <>
                    {showModalOrari &&
                    <ModalOrariSingolo showModalOrari={showModalOrari} toggleModalOrari={toggleModalOrari}
                                       ass={selectedAss}
                                       cbFn={aggiornaAssegnazione}/>}
                    {showModalOrariTutti &&
                    <ModalOrariTutti ordine={props.ordine} showModalOrariTutti={showModalOrariTutti}
                                     toggleModalOrariTutti={toggleModalOrariTutti}
                                     cbFn={aggiornaOrarioATutteLeAssegnazioni}/>}
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            firmaOrdine();
                        }}>

                        <FormGroup className={"row"}>
                            <Col xs={3}><Label className="col-form-label" for="contratto">Ut. firmatario</Label></Col>
                            <Col xs={9}><Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={utenteFirmante}
                                getOptionLabel={(item) => {

                                    if (item.isDisabled === true) return item.description;
                                    const cognome = _.get(item, "jsonextra.cognome");
                                    const nome = _.get(item, "jsonextra.nome");
                                    const matricola = _.get(item, "jsonextra.matricola");
                                    return cognome + ' ' + nome + ' (' + matricola + ')'

                                }}
                                onChange={value =>
                                    setUtenteFirmante(value)
                                }
                                options={[{
                                    value: "",
                                    description: "Utente",
                                    isDisabled: true
                                }, ...utenti]}
                                placeholder="Selez. un utente firmatario"
                            /></Col>
                        </FormGroup>
                        <SignaturePad ref={signaturePad} redrawOnResize={true} options={{backgroundColor: "white"}}/>
                        {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                        <h6 className={"pt-3"}>Elenco assegnazioni</h6>
                        <Row className={"p-3"}>
                            <Button onClick={toggleModalOrariTutti} color="primary" className={"col-12 "}>Modifica
                                orario a
                                tutti</Button>
                            {
                                assegnazioni.map(ass => (
                                    <Button onClick={() => toggleModalOrari(ass)}
                                            className={"col-12 "}>{getAssLabel(ass)}</Button>
                                            
                                ))
                            }
                        </Row>
                        <h6>Valutazione servizio</h6>
                        <StarRatings
                            rating={rating}
                            starRatedColor="yellow"
                            changeRating={changeRating}
                            numberOfStars={5}
                            starDimension="30px"
                            name='rating'
                        />
                    </form>

                    <FormGroup style={{marginTop: 20}} check>
                        <Label check>
                            <Input checked={correttaEsecuzione}
                                   onChange={cambioCorrettaEsecuzione}
                                   type="checkbox"/>{' '}
                            Il sottoscritto ha verificato la corretta prestazione del servizio secondo le modalità, le
                            caratteristiche qualitative, metodologiche e tecniche, utilizzate per la prestazione del
                            servizio, corrispondenti con quanto previsto nel contratto d’appalto e nei suoi allegati.

                            <span className="form-check-sign">
                <span className="check"></span>
                </span>
                        </Label>
                    </FormGroup>
                    <FormGroup style={{marginTop: 20}}>
                        <Col> <Input name="noteFirma" id="noteFirma"
                                     value={note}
                                     onChange={e => setNote(e.target.value)}
                                     type="textarea" placeholder={"Note ( descrivere qui eventuali non conformità )"}/>
                        </Col>
                    </FormGroup>
                    <CSSTransition
                        in={submitError}
                        appear={true}
                        unmountOnExit
                        classNames="error"
                        timeout={300}>
                        <blockquote className="blockquote blockquote-danger mt-2 mb-2">
                            <p className="mb-0 text-danger">Errore: {submitErrorDescr}</p>
                        </blockquote>
                    </CSSTransition>
                </>}

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                        firmaOrdine();
                    }}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
