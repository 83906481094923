import React, { Component } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  UncontrolledTooltip,
  CardSubtitle,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

import ReactTable from "react-table";
import PropTypes from "prop-types";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { connect } from "react-redux";
import { get } from "lodash";
import DateRangePickerWrapper from "components/DateRangePicker/DateRangePickerWrapper";
import * as Commands from "./redux/analytics.commands";
import Fade from "react-reveal/Fade";
import _ from "lodash";
import Input from "reactstrap/es/Input";
import moment from "moment";
import UpdateAnalyticsPaymentDateComponent from "./components/update_paymentDate.component.js";
import ReactTableFilterSelectComponent from "components/ReactTableFilter/reactTable.filter.select.component";
import {
  RUOLO_AMMINISTRATORE,
  RUOLO_AMMINISTRATORE_COOP,
} from "../utenti/components/costants";
import ReportAnalyticsFilter from "views/rai/analytics/components/report_analytics_filter";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";
import Viewer from "react-viewer";
import { OttieniImmagineFirma } from "views/rai/ordini/redux/ordini.commands";
import * as CommandsClienti from "../clienti/redux/clienti.commands";
import Select from "react-select";
import AdminWrapper from "components/AdminWrapper/AdminWrapper";

const SelectTable = selectTableHOC(ReactTable);

class AnalyticsComponent extends Component {
  static defaultProps = {
    keyField: "id",
  };

  static propTypes = {
    keyField: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showCreateModal: false,
      showUpdateModal: false,
      loading: false,
      selectedRecordForUpdate: {},
      showDeleteAlert: false,
      selectedRecordForDelete: {},
      startDate: new moment(),
      endDate: new moment(),
      initialStartDate: new moment(),
      initialEndDate: new moment(),
      fullText: "",
      selectAll: false,
      selection: [],
      showExportDropDown: false,
      showReportCanoniDropDown: false,
      showReportTrasportiDropDown: false,
      showReportsDropDown: false,
      showExportElencoPersonale: false,
      showExportServiziPianificati: false,
      rptName: "",
      rptData: "",
      rptFilter: { tipologia: [{}] },
      filters: {},
      customer: "",
      loadingCustomer: false,
      width: window.innerWidth,
      isMobile: false,
    };
  }

  onDatesChanged(startDate, endDate) {
    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        this.caricaDati(startDate, endDate, this.state.fullText);
      }
    );
  }

  handleResize = () => {
    let currentIsMobile = window.innerWidth <= 740;
    if (currentIsMobile !== this.state.isMobile) {
      this.setState({ width: window.innerWidth, isMobile: currentIsMobile });
    } else {
    }
    this.setState({ width: window.innerWidth });
  };

  caricaDati = async (startDate, endDate, fullText) => {
    const startDateClone = startDate.clone();
    const endDateClone = endDate.clone();

    startDateClone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    endDateClone.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });

    let customers = [];
    this.state.customer &&
      this.state.customer.map((item) => customers.push(item.id));

    this.setState({ loading: true });
    await Commands.Ottieni(
      startDateClone.unix(),
      endDateClone.unix(),
      fullText,
      customers
    );
    this.setState({ loading: false });
  };

  caricaClienti = async () => {
    this.setState({ loadingCustomer: true });
    await CommandsClienti.OttieniClienti();
    this.setState({ loadingCustomer: false });
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentDidMount() {
    this.caricaClienti();
    this.caricaDati(
      this.state.initialStartDate,
      this.state.initialEndDate,
      this.state.fullText
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleShowCreateModal = () => {
    this.setState({
      showCreateModal: !this.state.showCreateModal,
    });
  };

  toggleShowUpdatePaymentModal = () => {
    this.setState({
      showUpdatePaymentModal: !this.state.showUpdatePaymentModal,
      selectedRecordsForUpdate: this.state.selection,
    });
  };

  toggleShowUpdateApplicationModal = (record) => {
    this.setState({
      showUpdateApplicationModal: !this.state.showUpdateApplicationModal,
      selectedRecordForUpdate: record,
    });
  };

  toggleShowExportDropDown = () =>
    this.setState({ showExportDropDown: !this.state.showExportDropDown });
  toggleShowReportCanoniDropDown = () =>
    this.setState({
      showReportCanoniDropDown: !this.state.showReportCanoniDropDown,
    });
  toggleShowReportTrasportiDropDown = () =>
    this.setState({
      showReportTrasportiDropDown: !this.state.showReportTrasportiDropDown,
    });
  toggleShowReportDropDown = () =>
    this.setState({ showReportDropDown: !this.state.showReportDropDown });
  toggleShowActionDropDown = () =>
    this.setState({ showActionDropDown: !this.state.showActionDropDown });

  dataTable = () => {
    const wrappedInstance =
      this.checkboxTable && this.checkboxTable.getWrappedInstance();
    // the 'sortedData' property contains the currently accessible records based on the filter and sort
    const currentRecords = wrappedInstance
      ? wrappedInstance.getResolvedState().sortedData
      : this.props.data;
    const data = JSON.stringify(currentRecords.map((item) => item._original));
    return data;
  };

  dataTableIds = () => {
    const wrappedInstance =
      this.checkboxTable && this.checkboxTable.getWrappedInstance();
    // the 'sortedData' property contains the currently accessible records based on the filter and sort
    const currentRecords = wrappedInstance
      ? wrappedInstance.getResolvedState().sortedData
      : this.props.data;
    const data = currentRecords.map((item) => item._original.id);
    return data;
  };

  /**
   * Toggle a single checkbox for select table
   */
  toggleSelection = (key) => {
    // start off with the existing state

    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
  };

  /**
   * Toggle all checkboxes for select table
   */
  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance =
        this.checkboxTable && this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance
        ? wrappedInstance.getResolvedState().sortedData
        : this.props.data;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        selection.push(`select-${item._original[keyField]}`);
      });
    }
    this.setState({ selectAll, selection });
  };

  /**
   * Whether or not a row is selected for select table
   */
  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo) => {
    const { selection } = this.state;
    let scaduto = false;

    if (
      rowInfo &&
      rowInfo.original.state !== "firmato" &&
      rowInfo.original.type.toLowerCase() === "modulo"
    ) {
      scaduto =
        moment().diff(moment.unix(rowInfo.original.endTime), "hours") > 4;
    }

    let config = {
      onClick: (e, handleOriginal) => {
        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
      },
      style: {},
    };

    if (this.props.userAdmin && rowInfo) {
      if (scaduto || rowInfo.original.qty < 0) {
        config.style = {
          animation: "analyticsRosso 1s infinite",
        };
      }
    }

    return config;
  };

  toggleElencoPersonale = () => {
    this.setState({
      showExportElencoPersonale: !this.state.showExportElencoPersonale,
    });
  };

  toggleServiziPianificati = (rptName, rptFilter, rptData) => {
    this.setState({
      showExportServiziPianificati: !this.state.showExportServiziPianificati,
      rptName,
      rptFilter,
      rptData,
    });
  };

  downloadReport = async (rptName) => {
    const ids = this.dataTableIds();

    const res = await Commands.GetReportByAnalyticsIds(rptName, ids);

    if (res.error) {
      ShowConfirmationDialog({
        title: "Errore durante la creazione del report",
        text: res.message,
        canEscapeKeyCancel: true,
      });
      return;
    }

    let extension = ".xlsx";

    const element = document.createElement("a");
    let file;
    //file = res.data;
    file = res.data;
    element.href = res.data;
    element.download = rptName + extension;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  render() {
    const isMobile = this.state.width < 600;
    const content =       <>
        <Viewer
          visible={this.state.showSign}
          onClose={() => this.setState({ showSign: false })}
          images={[{ src: this.state.sign, alt: this.state.signAlt }]}
        />

        {this.state.showExportServiziPianificati && (
          <ReportAnalyticsFilter
            show={this.state.showExportServiziPianificati}
            rptName={this.state.rptName}
            rptFilter={this.state.rptFilter}
            rptData={this.state.rptData}
            toggle={this.toggleServiziPianificati}
          />
        )}
        {this.state.showUpdatePaymentModal ? (
          <UpdateAnalyticsPaymentDateComponent
            {...this.state}
            show={this.state.showUpdatePaymentModal}
            toggle={this.toggleShowUpdatePaymentModal}
          />
        ) : null}

        {this.state.showUpdateApplicationModal ? (
          <UpdateAnalyticsPaymentDateComponent
            {...this.state.selectedRecordForUpdate}
            show={this.state.showUpdateApplicationModal}
            toggle={this.toggleShowUpdateApplicationModal}
          />
        ) : null}

        {/*Contenuto pagina*/}
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  {/*Filtri*/}
                  <Row>
                    <Col xs={12} sm={12} lg={4} className={`d-flex`}>
                      <DateRangePickerWrapper
                        orientation={
                          this.state.isMobile ? "vertical" : "horizontal"
                        }
                        disabled={false}
                        initialStartDate={this.state.initialStartDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        initialEndDate={this.state.initialEndDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        small
                        enableOutsideDays={true}
                        startDatePlaceholderText={"Data inizio"}
                        endDatePlaceholderText={"Data fine"}
                        showDefaultInputIcon
                        isOutsideRange={() => false}
                        onChange={(startDate, endDate) =>
                          this.onDatesChanged(startDate, endDate)
                        }
                      />
                    </Col>
                    {!isMobile && (
                      <Col xs={12} lg={5}>
                        <Input
                          onChange={(e) => {
                            this.setState({ fullText: e.target.value }, () => {
                              this.caricaDati(
                                this.state.startDate,
                                this.state.endDate,
                                this.state.fullText
                              );
                            });
                          }}
                          value={this.state.fullText}
                          placeHolder="Ricerca...."
                        />
                      </Col>
                    )}

                    <Col className="d-flex justify-content-end">
                      {!isMobile && (
                        <>
                          <ButtonDropdown
                            isOpen={this.state.showExportDropDown}
                            toggle={this.toggleShowExportDropDown}
                          >
                            <DropdownToggle className="btn btn-primary" caret>
                              Reports moduli
                            </DropdownToggle>
                            <DropdownMenu>
                              {/*<DropdownItem onClick={() => this.scaricaReportExcel01()}>0.1 -*/}
                              {/*    Ordini Excel</DropdownItem>*/}
                              {/*<DropdownItem divider/>*/}
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfOreLavoratoriPerSettoreModuli_rpt01"
                                  )
                                }
                              >
                                [1] - Riepilogo ore lavoratori
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfServiziPianificatiRiepilogo_rpt02",
                                    { tipologia: ["Modulo"] }
                                  )
                                }
                              >
                                [2] - Riepilogo servizi pianificati
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfPianificatoVsConsuntivo_rpt03"
                                  )
                                }
                              >
                                [3] - Pianificato VS consuntivo
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfPianificatoVsConsuntivoInDiminuzione_rpt03"
                                  )
                                }
                              >
                                [3] - Pianificato VS consuntivo (diminuzione)
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfServiziPianificatiDettaglio_rpt04",
                                    { tipologia: ["Modulo"] }
                                  )
                                }
                              >
                                [4] - Dettaglio servizi pianificati
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfAttestazioneCorrettaEsecuzioneModuli_rpt07"
                                  )
                                }
                              >
                                [7] - Attestazione corretta esecuzione moduli
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptServiziResiNelPeriodo"
                                  )
                                }
                              >
                                Servizi resi nel periodo
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfVariazioniSettimanaliModulo_rpt05"
                                  )
                                }
                              >
                                [5] - Variazioni pianificazione settimanali
                                (Modulo){" "}
                              </DropdownItem>

                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfVariazioniMensiliModulo_rpt06"
                                  )
                                }
                              >
                                [6] - Variazioni pianificazione mensile (Modulo)
                              </DropdownItem>

                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptTsvTracciatoSapPianificazione",
                                    { tipologia: ["Modulo"] }
                                  )
                                }
                              >
                                Tracciato SAP pianificazione - Dettaglio
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptTsvTracciatoSapPianificazioneRiepilogo",
                                    { tipologia: ["Modulo"] }
                                  )
                                }
                              >
                                Tracciato SAP pianificazione - Riepilogo
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptTsvTracciatoSapConsuntivoDettaglio"
                                  )
                                }
                              >
                                Tracciato SAP consuntivo - Dettaglio
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptTsvTracciatoSapConsuntivoRiepilogo"
                                  )
                                }
                              >
                                Tracciato SAP consuntivo - Riepilogo
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptTsvTracciatoSapVarianteInAumento"
                                  )
                                }
                              >
                                Tracciato SAP variazione in aumento
                              </DropdownItem>

                              {/*<DropdownItem*/}
                              {/*    onClick={() => this.toggleServiziPianificati("rptXlsTracciatoSapPianificazione", {tipologia: "Modulo"})}>Tracciato*/}
                              {/*    SAP pianificazione Excel</DropdownItem>*/}

                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptXlsOrdiniDettagliatoEliminatiBIS"
                                  )
                                }
                              >
                                Report Excel Storico
                              </DropdownItem>

                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptXlsOrdini",
                                    {},
                                    this.dataTableIds()
                                  )
                                }
                              >
                                Ordini Excel
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptXlsOrdiniDettagliato",
                                    {},
                                    this.dataTableIds()
                                  )
                                }
                              >
                                Ordini Excel dettagliato
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>

                          <ButtonDropdown
                            isOpen={this.state.showReportTrasportiDropDown}
                            toggle={this.toggleShowReportTrasportiDropDown}
                          >
                            <DropdownToggle className="btn btn-primary" caret>
                              Reports Trasporti
                            </DropdownToggle>
                            <DropdownMenu>
                              {/*<DropdownItem onClick={() => this.scaricaReportExcel01()}>0.1 -*/}
                              {/*    Ordini Excel</DropdownItem>*/}
                              {/*<DropdownItem divider/>*/}
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfOreLavoratoriPerSettoreTrasporti_rpt01"
                                  )
                                }
                              >
                                [1] - Riepilogo ore lavoratori
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptTsvTracciatoSapConsuntivoRiepilogoTrasporti"
                                  )
                                }
                              >
                                Tracciato SAP consuntivo - Riepilogo
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptXlsOrdiniDettagliatoEliminatiBISTrasporti"
                                  )
                                }
                              >
                                Report Excel Storico
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptXlsOrdiniDettagliatoTrasporti",
                                    {},
                                    this.dataTableIds()
                                  )
                                }
                              >
                                Ordini Excel dettagliato
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                          <ButtonDropdown
                            isOpen={this.state.showReportCanoniDropDown}
                            toggle={this.toggleShowReportCanoniDropDown}
                          >
                            <DropdownToggle className="btn btn-primary" caret>
                              Reports canoni
                            </DropdownToggle>
                            <DropdownMenu>
                              {/*<DropdownItem onClick={() => this.scaricaReportExcel01()}>0.1 -*/}
                              {/*    Ordini Excel</DropdownItem>*/}
                              {/*<DropdownItem divider/>*/}
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfOreLavoratoriPerSettoreCanoni_rpt01"
                                  )
                                }
                              >
                                [1] - Riepilogo ore lavoratori
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfPianificatoCanoni_rpt02",
                                    { tipologia: ["Canone"] }
                                  )
                                }
                              >
                                [2] - Riepilogo servizi pianificati
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfPianificatoVsConsuntivo_rpt03"
                                  )
                                }
                              >
                                [3] - Pianificato VS consuntivo
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfPianificatoVsConsuntivoInDiminuzione_rpt03"
                                  )
                                }
                              >
                                [3] - Pianificato VS consuntivo (diminuzione)
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfServiziPianificatiDettaglio_rpt04",
                                    { tipologia: ["Canone"] }
                                  )
                                }
                              >
                                [4] - Dettaglio servizi pianificati
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptPdfAttestazioneCorrettaEsecuzioneCanoni_rpt08"
                                  )
                                }
                              >
                                [8] - Attestazione corretta esecuzione
                              </DropdownItem>

                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptTsvTracciatoSapPianificazione",
                                    { tipologia: ["Canone"] }
                                  )
                                }
                              >
                                Tracciato SAP pianificazione
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptTsvTracciatoSapConsuntivoCanoni"
                                  )
                                }
                              >
                                Tracciato SAP consuntivo
                              </DropdownItem>

                              {/*<DropdownItem*/}
                              {/*    onClick={() => this.toggleServiziPianificati("rptXlsTracciatoSapPianificazione", {tipologia: "Modulo"})}>Tracciato*/}
                              {/*    SAP pianificazione Excel</DropdownItem>*/}

                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptXlsOrdiniDettagliatoEliminatiBIS"
                                  )
                                }
                              >
                                Report Excel Storico
                              </DropdownItem>

                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptXlsOrdini",
                                    {},
                                    this.dataTableIds()
                                  )
                                }
                              >
                                Ordini Excel
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.toggleServiziPianificati(
                                    "rptXlsOrdiniDettagliato",
                                    {},
                                    this.dataTableIds()
                                  )
                                }
                              >
                                Ordini Excel dettagliato
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </>
                      )}

                      {this.props.userAdmin && !isMobile && (
                        <ButtonDropdown
                          isOpen={this.state.showReportDropDown}
                          toggle={this.toggleShowReportDropDown}
                        >
                          <DropdownToggle className="btn btn-primary" caret>
                            Reports
                          </DropdownToggle>
                          <DropdownMenu>
                            {/*<DropdownItem onClick={() => this.scaricaReportExcel01()}>0.1 -*/}
                            {/*    Ordini Excel</DropdownItem>*/}
                            {/*<DropdownItem divider/>*/}
                            <DropdownItem
                              onClick={() =>
                                this.downloadReport("rptRiepilogoApplicazioni")
                              }
                            >
                              Riepilogo Applicazioni
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                this.downloadReport(
                                  "rptRiepilogoContrattiLotti"
                                )
                              }
                            >
                              Riepilogo contratti/lotti
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      )}
                      {this.props.userAdmin && !isMobile && (
                        <ButtonDropdown
                          isOpen={this.state.showActionDropDown}
                          toggle={this.toggleShowActionDropDown}
                        >
                          <DropdownToggle className="btn btn-primary" caret>
                            Azioni
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={this.toggleShowUpdatePaymentModal}
                            >
                              Imposta applicazione/pagamento
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      )}

                      {this.props.userAdmin && isMobile && (
                        <Select
                          className="react-select primary selectAnalytics"
                          classNamePrefix="react-select"
                          value={this.state.customer}
                          loading={this.state.loadingCustomer}
                          getOptionLabel={(item) => item.companyName}
                          getOptionValue={(item) => item.id}
                          closeMenuOnSelect={false}
                          isMulti
                          onChange={(value) =>
                            this.setState({ customer: value }, () => {
                              this.caricaDati(
                                this.state.startDate,
                                this.state.endDate,
                                this.state.fullText
                              );
                            })
                          }
                          options={[
                            {
                              value: "",
                              description: "Cliente",
                              isDisabled: true,
                            },
                            ...this.props.customers,
                          ]}
                          placeholder="Seleziona un cliente"
                        />
                      )}

                      <button
                        id="refresh"
                        onClick={() => {
                          this.caricaDati(
                            this.state.startDate,
                            this.state.endDate,
                            this.state.fullText
                          );
                        }}
                        className="btn btn-primary btn-fab btn-icon "
                      >
                        <i className="tim-icons icon-refresh-01"></i>
                      </button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="refresh"
                        delay={0}
                      >
                        Ricarica i dati
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Elenco servizi</CardTitle>
                  <Fade when={this.state.loading}>
                    <CardSubtitle>
                      Caricamento in corso{" "}
                      <i className="fa fa-spin fa-spinner" />
                    </CardSubtitle>
                  </Fade>
                </CardHeader>
                <CardBody>
                  <SelectTable
                    data={this.props.data}
                    filterable
                    noDataText={"Nessun dato"}
                    loadingText={"Caricamento in corso"}
                    loading={false}
                    showPagination={true}
                    nextText={"Avanti"}
                    previousText={"Indietro"}
                    pageText={"Pagina"}
                    ofText={"di"}
                    rowsText={"elementi"}
                    keyField={"id"}
                    ref={(r) => (this.checkboxTable = r)}
                    toggleSelection={this.toggleSelection}
                    selectAll={this.state.selectAll}
                    selectType="checkbox"
                    toggleAll={this.toggleAll}
                    isSelected={this.isSelected}
                    getTrProps={this.rowFn}
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                        width: 60,
                        show: false,
                      },
                      {
                        Header: "Num. Ord",
                        accessor: "orderNumber",
                        maxWidth: 100,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row.orderNumber
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "orderNumber";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                "orderNumber",
                              ])}
                              optionValue={"orderNumber"}
                              optionLabel={"orderNumber"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        show: this.props.userAdmin,
                        Header: "Contratto",
                        accessor: "contract",
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row.contract
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "contract";

                          return (
                            <ReactTableFilterSelectComponent
                              //options={currentRecords}
                              options={_.sortBy(this.props.data, ["contract"])}
                              optionValue={"contract"}
                              optionLabel={"contract"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Data",
                        accessor: "date",
                        filterable: false,
                        Cell: (row) => {
                          if (!row.value) return "-";
                          return (
                            <div>
                              {moment.unix(row.value).format("DD/MM/YYYY")}
                            </div>
                          );
                        },
                        width: 80,
                      },

                      {
                        Header: "Tipo",
                        accessor: "orderType",
                        Cell: (cellInfo) => {
                          const state =
                            cellInfo.original.orderType.toLowerCase();
                          switch (state) {
                            case "planned":
                              return (
                                <div className={"ordinePianificato"}>
                                  Pianificato
                                </div>
                              );
                            case "ordinary":
                              return (
                                <div className={"ordineVariazione"}>
                                  Variazione
                                </div>
                              );
                            default:
                              return "-";
                          }
                        },
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row.orderType
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "orderType";
                          return (
                            <ReactTableFilterSelectComponent
                              options={[
                                { value: "planned", label: "Pianificato" },
                                { value: "ordinary", label: "Variazione" },
                              ]}
                              optionValue={"value"}
                              optionLabel={"label"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Stato",
                        accessor: "state",
                        Cell: (cellInfo) => {
                          const state = cellInfo.original.state.toLowerCase();
                          switch (state) {
                            case "approvato":
                              return (
                                <div className={"ordineApprovato"}>{state}</div>
                              );
                            case "firmato":
                              return (
                                <div className={"ordineFirmato"}>{state}</div>
                              );
                            case "rifiutato":
                              return (
                                <div className={"ordineRifiutato"}>{state}</div>
                              );
                            default:
                              return (
                                <div className={"ordineCreato"}>{state}</div>
                              );
                          }
                        },
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row.state
                          );
                        },
                        Filter: ({ filter, onChange }) => {
                          const filterName = "stato";
                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, ["state"])}
                              optionValue={"state"}
                              optionLabel={"state"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Firma",
                        Cell: (row) => {
                          if (row.original.state.toLowerCase() !== "firmato") {
                            return null;
                          }

                          if (this.state.loadingRow === row.original.id) {
                            return <i className="fa fa-spin fa-spinner" />;
                          }

                          return (
                            <Button
                              className="btn-link btn-icon btn-primary"
                              data-toggle="dropdown"
                              color={"white"}
                              type="button"
                              onClick={async () => {
                                this.setState({ loadingRow: row.original.id });
                                const res = await OttieniImmagineFirma(
                                  row.original.orderId
                                );
                                this.setState({ loadingRow: "" });
                                if (res.hasError) {
                                  ShowConfirmationDialog({
                                    title:
                                      "Errore durante l'ottenimento della firma",
                                    text: res.message,
                                    confirmBtnText: "Ok",
                                    canEscapeKeyCancel: true,
                                    showCancel: false,
                                  });
                                  return;
                                }
                                this.setState({
                                  showSign: true,
                                  sign: res.data,
                                  signAlt:
                                    "Firma ordine " + row.original.number,
                                });
                              }}
                            >
                              <i className="tim-icons icon-image-02" />
                            </Button>
                          );
                        },
                        filterable: false,
                      },
                      {
                        Header: "Settore",
                        accessor: "sector",
                        width: 160,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some((f) => {
                            const matcher = _.get(row._original, "sector", "");
                            return f.value === matcher;
                          });
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "sector";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, ["sector"])}
                              optionValue={"sector"}
                              optionLabel={"sector"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Richiedente",
                        accessor: "applicantUser",
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.applicantUser
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "applicantUser";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                "applicantUser",
                              ])}
                              optionValue={"applicantUser"}
                              optionLabel={"applicantUser"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Approvante",
                        accessor: "approvingUser",
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.approvingUser
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "approvingUser";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                "approvingUser",
                              ])}
                              optionValue={"approvingUser"}
                              optionLabel={"approvingUser"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Lotto",
                        accessor: "lot",
                        width: 80,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.lot
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "lot";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, ["lot"])}
                              optionValue={"lot"}
                              optionLabel={"lot"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Tipologia",
                        accessor: "type",
                        width: 80,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.type
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "type";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, ["type"])}
                              optionValue={"type"}
                              optionLabel={"type"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "UORG",
                        accessor: "uorg",
                        width: 70,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.uorg
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "uorg";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, ["uorg"])}
                              optionValue={"uorg"}
                              optionLabel={"uorg"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Studio",
                        accessor: "study",
                        width: 80,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;

                          const res = filter.value.some((f) => {
                            const matcher = _.get(row._original, "study", "");
                            return f.value === matcher;
                          });

                          return res;
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "study";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, ["study"])}
                              optionValue={"study"}
                              optionLabel={"study"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Produzione",
                        accessor: "production",
                        width: 200,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.production
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "production";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                "production",
                              ])}
                              optionValue={"production"}
                              optionLabel={"production"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Orario",
                        show: this.props.userAdmin,
                        width: 120,
                        Cell: (row) => {
                          return (
                            <div>
                              {moment
                                .unix(row.original.startTime)
                                .format("HH:mm")}{" "}
                              ->{" "}
                              {moment
                                .unix(row.original.endTime)
                                .format("HH:mm")}
                            </div>
                          );
                        },
                        Filter: <></>,
                      },

                      {
                        Header: "Servizio",
                        accessor: "article",
                        width: 80,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.article
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "article";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, ["article"])}
                              optionValue={"article"}
                              optionLabel={"article"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Voce",
                        accessor: "articleNumber",
                        width: 60,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.articleNumber
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "articleNumber";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                "articleNumber",
                              ])}
                              optionValue={"articleNumber"}
                              optionLabel={"articleNumber"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Ore ass/tot",
                        accessor: "totalHours",
                        show: this.props.userAdmin,
                        width: 150,
                        Cell: (row) => {
                          let className = "assegnazioneAssente";
                          const oreAssegnate = row.original.totalAssignedHours;
                          const oreTotali = row.original.totalHours;

                          if (oreAssegnate === oreTotali) {
                            className = "assegnazioneCompleta";
                          }

                          return (
                            <div className={className}>
                              {" "}
                              {oreAssegnate.toLocaleString()} /{" "}
                              {oreTotali.toLocaleString()}
                            </div>
                          );
                        },
                        Footer: (table) => {
                          let className = "assegnazioneAssente";
                          const oreAssegnate = _.round(
                            _.sum(
                              _.map(table.data, (d) =>
                                isNaN(d._original.totalAssignedHours)
                                  ? 0
                                  : d._original.totalAssignedHours
                              )
                            )
                          );
                          const oreTotali = _.round(
                            _.sum(_.map(table.data, (d) => d.totalHours))
                          );

                          if (oreAssegnate === oreTotali) {
                            className = "assegnazioneCompleta";
                          }

                          return (
                            <div className={className}>
                              Tot: {oreAssegnate.toLocaleString()} /{" "}
                              {oreTotali.toLocaleString()}
                            </div>
                          );
                        },
                        filterable: false,
                      },
                      {
                        Header: "Costo unitario",
                        accessor: "unitSellPrice",
                        width: 150,
                        Cell: (row) => {
                          return (
                            <div>
                              {Number(row.value).toLocaleString("es-ES", {
                                minimumFractionDigits: 2,
                              }) + " €"}
                            </div>
                          );
                        },
                        filterable: false,
                      },
                      {
                        Header: "Q.tà",
                        accessor: "qty",
                        width: 80,

                        Cell: (row) => {
                          let className = "analyticsCentrata";

                          return (
                            <div className={className}> {row.original.qty}</div>
                          );
                        },
                        Footer: (footer) => {
                          const tot = _.round(
                            _.sum(_.map(footer.data, (d) => d.qty))
                          );
                          let className = "";
                          return <div className={className}>Tot: {tot}</div>;
                        },
                        filterable: false,
                      },

                      {
                        Header: "Fatturato",
                        accessor: "totalSellPrice",
                        width: 150,
                        Cell: (row) => {
                          return (
                            <div className={"importoFatturato"}>
                              {(
                                row.original.unitSellPrice * row.original.qty
                              ).toLocaleString("es-ES", {
                                minimumFractionDigits: 2,
                              }) + " €"}
                            </div>
                          );
                        },
                        Footer: (footer) => (
                          <div className={"importoFatturato"}>
                            Tot:{" "}
                            {_.sum(
                              _.map(footer.data, (d) => d.unitSellPrice * d.qty)
                            ).toLocaleString("it-IT", {
                              minimumFractionDigits: 2,
                            })}{" "}
                            €
                          </div>
                        ),
                        filterable: false,
                      },
                      {
                        Header: "Luogo partenza",
                        accessor: "luogoPartenza",
                        show: this.props.userAdmin,
                        width: 150,
                      },
                      {
                        Header: "Luogo arrivo",
                        accessor: "luogoArrivo",
                        show: this.props.userAdmin,
                        width: 150,
                      },
                      {
                        Header: "Km",
                        accessor: "kilometri",
                        show: this.props.userAdmin,
                        width: 150,
                        filterable: false,
                      },
                      {
                        Header: "Applicazione",
                        accessor: "applicationNumber",
                        show: this.props.userAdmin,
                        width: 130,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;

                          if (filter.value[0].value === "Con applicazione")
                            return row._original.applicationNumber != ""
                              ? true
                              : false;

                          if (filter.value[0].value === "Senza applicazione")
                            return row._original.applicationNumber === ""
                              ? true
                              : false;

                          return filter.value.some(
                            (f) => f.value === row._original.applicationNumber
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "applicationNumber";

                          return (
                            <ReactTableFilterSelectComponent
                              options={[
                                { applicationNumber: "Con applicazione" },
                                { applicationNumber: "Senza applicazione" },
                                ..._.sortBy(this.props.data, [
                                  "applicationNumber",
                                ]),
                              ]}
                              optionValue={"applicationNumber"}
                              optionLabel={"applicationNumber"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Convalidato da",
                        accessor: "validated_by",
                        filterable: false,
                        show: this.props.userAdmin,
                      },
                      {
                        Header: "Convalidato il",
                        accessor: "validated_at",
                        show: this.props.userAdmin,
                        Cell: (row) => {
                          if (!row.value) return "-";
                          return (
                            <div>
                              {moment.unix(row.value).format("DD/MM/YYYY")}
                            </div>
                          );
                        },
                        filterable: false,
                      },

                      {
                        Header: "Data pagamento",
                        accessor: "paymentDate",
                        show: this.props.userAdmin,
                        Cell: (row) => {
                          if (!row.value) return "-";
                          return (
                            <div>
                              {moment.unix(row.value).format("DD/MM/YYYY")}
                            </div>
                          );
                        },
                        width: 150,
                        filterable: true,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.paymentDate
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "paymentDate";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(
                                this.props.data,
                                ["paymentDate"]
                              ).filter((val) => {
                                return val.paymentDate > 0;
                              })}

                              optionValue={"paymentDate"}
                              getOptionLabel={(row) => {
                                return moment.unix(row.value).format("DD/MM/YYYY")
                              }}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                      {
                        Header: "Fattura",
                        show: this.props.userAdmin,
                        accessor: "invoiceNumber",
                        width: 100,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;

                          if (filter.value[0].value === "Da fatturare")
                            return row._original.invoiceNumber === ""
                              ? true
                              : false;

                          if (filter.value[0].value === "Fatturate")
                            return row._original.invoiceNumber != ""
                              ? true
                              : false;

                          return filter.value.some(
                            (f) => f.value === row._original.invoiceNumber
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "invoiceNumber";

                          return (
                            <ReactTableFilterSelectComponent
                              options={[
                                { invoiceNumber: "Da fatturare" },
                                { invoiceNumber: "Fatturate" },
                                ..._.sortBy(this.props.data, ["invoiceNumber"]),
                              ]}
                              optionValue={"invoiceNumber"}
                              optionLabel={"invoiceNumber"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },

                      {
                        Header: "Scadenza Fattura",
                        accessor: "invoiceDueDate",
                        show: this.props.userAdmin,
                        Cell: (row) => {
                          if (!row.value) return "-";
                          return (
                            <div>
                              {moment.unix(row.value).format("DD/MM/YYYY")}
                            </div>
                          );
                        },
                        width: 150,
                        filterable: false,
                      },

                      {
                        Header: "Data pagamento",
                        accessor: "paymentDate",
                        show: this.props.userAdmin,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;

                          if (filter.value[0].value === "Da fatturare")
                            return row._original.invoiceNumber === ""
                              ? true
                              : false;

                          if (filter.value[0].value === "Fatturate")
                            return row._original.invoiceNumber != ""
                              ? true
                              : false;

                          return filter.value.some(
                            (f) => f.value === row._original.invoiceNumber
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "invoiceNumber";

                          return (
                            <ReactTableFilterSelectComponent
                              options={[
                                { invoiceNumber: "Da fatturare" },
                                { invoiceNumber: "Fatturate" },
                                ..._.sortBy(this.props.data, ["invoiceNumber"]),
                              ]}
                              optionValue={"invoiceNumber"}
                              optionLabel={"invoiceNumber"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                        Cell: (row) => {
                          if (!row.value) return "-";
                          return (
                            <div>
                              {moment.unix(row.value).format("DD/MM/YYYY")}
                            </div>
                          );
                        },
                        width: 150,
                        filterable: false,
                      },

                      {
                        Header: "Stato Pagamento", //Da fatturare, In attesa applicazione, Fatturato, In attesa pagamento, Pagato
                        accessor: "paymentStatus",
                        show: this.props.userAdmin,
                        width: 150,
                        filterMethod: (filter, row) => {
                          if (
                            filter &&
                            filter.value &&
                            filter.value.length === 0
                          )
                            return true;
                          return filter.value.some(
                            (f) => f.value === row._original.paymentStatus
                          );
                        },
                        Filter: ({ onChange }) => {
                          const filterName = "paymentStatus";

                          return (
                            <ReactTableFilterSelectComponent
                              options={_.sortBy(this.props.data, [
                                "paymentStatus",
                              ])}
                              optionValue={"paymentStatus"}
                              optionLabel={"paymentStatus"}
                              filterName
                              value={this.state.filters[filterName]}
                              onChange={(value) =>
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [filterName]: value,
                                    },
                                  },
                                  () => onChange(value)
                                )
                              }
                            />
                          );
                        },
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>

    return (<AdminWrapper children={content}/>)
    
  }
}

const mapStateToProps = (state) => {
  let userAdmin = false;
  let userRole = get(state, "login.user.role", "-");

  if (
    userRole.toLowerCase() === RUOLO_AMMINISTRATORE ||
    userRole.toLowerCase() === RUOLO_AMMINISTRATORE_COOP
  ) {
    userAdmin = true;
  }

  const data = {
    customers: get(state, "customer.data", []),
    data: get(state, "analytics.data", []),
    loading: get(state, "analytics.loading", false),
    userAdmin,
    userRole,
  };

  return data;
};

export default connect(mapStateToProps, () => ({}))(AnalyticsComponent);
