import * as _ from 'lodash';
import store from 'store/store';

export const BEAUTIFY_RUOLO = ruolo => {
    const r = ruoli.filter(r => r.value === ruolo)[0];
    if (r) return r.description;
    return "-";
};

export const CurrentUser = () => {
    if (!store.getState().login.logged) return false;
    const user = store.getState().login.user;
    return user;
};

// noinspection JSUnusedGlobalSymbols
export const GetUsersForRoleAndSector = (sectorId, role) => {
    const users = store.getState().raiUtenti.data;
    const res = [];

    users.forEach(user => {
        const sectorRoles = user.jsonextra.sectorsRoles;

        if (sectorRoles && sectorRoles.length > 0) {
            sectorRoles.forEach(sectorRole => {
                if (sectorRole.sector && sectorRole.sector.id === sectorId && sectorRole.role && sectorRole.role.value === role) res.push(user)
            });
        };
    });

    return res;
};

export const GetSectorsForRole = role => {
    if (!store.getState().login.logged) return [];
    const user = store.getState().login.user;
    const sectorRoles = user.jsonextra.sectorsRoles;

    const res = [];
    if (sectorRoles && sectorRoles.length > 0) {
        sectorRoles.forEach(sectorRole => {
            if (sectorRole.role.value === role) res.push(sectorRole)
        });
    }

    return res;
};

export const GetSectorsForRoles = roles => {
    if (!store.getState().login.logged) return [];
    if (!roles || roles.length === 0) return [];

    const user = store.getState().login.user;
    const sectorRoles = user.jsonextra.sectorsRoles;

    const res = [];
    if (sectorRoles && sectorRoles.length > 0) {
        sectorRoles.forEach(sectorRole => {
            if (roles.some(r => r === sectorRole.role.value)) res.push(sectorRole);
        });
    }

    return res;
};


export const isAdmin = () => {
    if (!store.getState().login.logged) return false;
    const user = store.getState().login.user;
    return user.role === RUOLO_AMMINISTRATORE;
};


export const isAdminCoop = () => {
    if (!store.getState().login.logged) return false;
    const user = store.getState().login.user;
    return user.role === RUOLO_AMMINISTRATORE_COOP;
}

export const isAdminOrAdminCoop = () => {
    if (!store.getState().login.logged) return false;
    const user = store.getState().login.user;
    return user.role === RUOLO_AMMINISTRATORE_COOP || user.role === RUOLO_AMMINISTRATORE;
}

export const IsRole = (role) => {
    if (!store.getState().login.logged) return false;
    const user = store.getState().login.user;
    return user.role === role;
};

export const canInsertPlanedOrder = () => {
    const user = store.getState().login.user;
    return _.get(user, "jsonextra.abilitatoPianificazione", false)
};


// noinspection JSUnusedGlobalSymbols
export const OneOfRole = requiredRoles => {
    if (!requiredRoles) return true;
    if (!store.getState().login.logged) return false;
    const user = store.getState().login.user;
    const sectorRoles = user.jsonextra.sectorsRoles;
    const userRoles = [user.role];

    if (sectorRoles && sectorRoles.length > 0) {
        sectorRoles.map(sectorRole => userRoles.push(sectorRole.role.value));
    }

    return requiredRoles.some(r => userRoles.includes(r));
};

export const GetLowerRolesForRole = () => {
    const allowed = ruoli.filter(ruolo => OneOfRole(ruolo.visibleTo));
    // console.log('ALLOWED', allowed);
    return allowed;
};

export const RUOLO_FIRMATARIO_STATINI_RAI = 'firmatario_statini_rai';
export const RUOLO_RICHIEDENTE_RAI = 'richiedente_rai';
export const RUOLO_OPERATORE_SAP_RAI = 'operatore_sap_rai';
export const RUOLO_REFERENTE_SAP_RAI = 'referente_sap_rai';
export const RUOLO_AUSILIARIO_RAI = 'ausiliario_rai';
export const RUOLO_REFERENTE_RAI = 'referente_rai';
export const RUOLO_REFERENTE_CANONE = 'referente_canone_rai';
export const RUOLO_SUPERVISORE_RAI = 'supervisore_rai';
export const RUOLO_FUNZIONARIO_RAI = 'funzionario_rai';
export const RUOLO_COGECO_RAI = 'cogeco_rai';
export const RUOLO_AMMINISTRATORE = 'amministratore';
export const RUOLO_AMMINISTRATORE_COOP = 'amministratore_coop';
export const RUOLO_UTENTE = 'utente_rai'; // ruolo generico, i permessi sono specificati per ogni settore
export const RUOLO_OPERATIVO = 'operativo'; // ruolo generico, i permessi sono specificati per ogni settore
export const RUOLO_FIRMA_ORDINI = 'firma_ordini_dashboard';

export const ruoli = [
    {
        value: RUOLO_UTENTE,
        description: "Utente Rai",
        visibleTo: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
    },
    {
        value: RUOLO_OPERATIVO,
        description: "Operativo",
        visibleTo: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
    },
    {
        value: RUOLO_AMMINISTRATORE_COOP,
        description: "Amministratore_coop",
        visibleTo: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
    },
    {
        value: RUOLO_AMMINISTRATORE,
        description: "Amministratore",
        visibleTo: [RUOLO_AMMINISTRATORE]
    },
];

export const ruoliSettori = [
    {
        value: RUOLO_OPERATIVO, // non vede nessuna view
        description: "Operativo"
    },
    {
        value: RUOLO_FIRMATARIO_STATINI_RAI, // non vede nessuna view
        description: "Firmatario statini Rai"
    },
    {
        value: RUOLO_RICHIEDENTE_RAI, // es. Super operatore // può approvare solo quelle nel mese corrente e vede solo ed esclusivamente ORDINI
        description: "Richiedente Rai"
    },
    {
        value: RUOLO_OPERATORE_SAP_RAI, // vedere solo analaytics
        description: "Operatore SAP Rai"
    },
    {
        value: RUOLO_REFERENTE_SAP_RAI,  // vedere solo analaytics  e ricevere email con il report dopo che è stato approvato dal funzionario
        description: "Referente SAP Rai"
    },
    {
        value: RUOLO_SUPERVISORE_RAI, // ex, Manager
        description: "Supervisore Rai"
    },
    {
        value: RUOLO_FUNZIONARIO_RAI,  // riceve report pianificazione e lo approva
        description: "Funzionario Rai"
    },
    {
        value: RUOLO_COGECO_RAI,  // lettura su tutto
        description: "Co.Ge.Co"
    },
    {
        value: RUOLO_REFERENTE_RAI,
        description: "Referente Rai"
    },
    {
        value: RUOLO_REFERENTE_CANONE,
        description: "Referente Canone Rai"
    },
    {
        value: RUOLO_AUSILIARIO_RAI,
        description: "Ausiliario Rai"
    },
    {
        value: RUOLO_FIRMA_ORDINI,
        description: "Abilitato alla firma"
    }
];
